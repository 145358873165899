import React from 'react';
import {View} from 'react-native';
import {useModalState} from '../Providers/ModalProvider';
import StripeProvider from '../Providers/StripeProvider';
import LoginModal from './LoginModal';
import StripeModal from './StripeModal';
import NewJobModal from './NewJobModal';
import Prompt from './Prompt';
import SearchModal from 'src/Modals/SearchModal';

export default function Modals(props) {
  const {searchOpen, loginModalOpen, prompt, newJobModalOpen, stripeModalOpen} =
    useModalState();
  console.log({loginModalOpen, newJobModalOpen});
  return (
    <View>
      {!!loginModalOpen && <LoginModal />}
      {!!prompt && <Prompt />}
      {!!newJobModalOpen && <NewJobModal />}
      {!!searchOpen && <SearchModal />}
      {!!stripeModalOpen && (
        <StripeProvider>
          <StripeModal type={stripeModalOpen} />
        </StripeProvider>
      )}
    </View>
  );
}
