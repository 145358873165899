import React, {useState, useEffect} from 'react';

import {View, TextInput, Text} from 'react-native';

// @ts-ignore
import Modal from '../Common/Modal';
import DropDown from '../Common/Dropdown';
import {BodyText, ContinueButton} from '../Common/Text';
import {useModalState} from '../Providers/ModalProvider';

export default function Prompt() {
  const {prompt, setPrompt} = useModalState();
  const [value, setValue] = useState(prompt.intialValue || '');
  const [value2, setValue2] = useState(prompt.intialValue2 || '');
  const [value3, setValue3] = useState(prompt.intialValue3 || '');
  const [value4, setValue4] = useState(prompt.intialValue4 || '');

  useEffect(() => {
    if (prompt.intialValue) {
      setValue(prompt.intialValue || '');
      setValue(prompt.intialValue2 || '');
    }
  }, [prompt.intialValue]);

  // resetting the modal on close
  useEffect(() => {
    setValue('');
  }, [prompt?.open]);

  // giving an intitial value
  useEffect(() => {
    if (prompt?.value) {
      setValue(prompt?.value || '');
    }
  }, [prompt?.value]);

  // giving an intitial value for option 2
  useEffect(() => {
    if (prompt?.value2) {
      setValue2(prompt?.value2 || '');
    }
  }, [prompt?.value2]);

  // giving an intitial value for option 3
  useEffect(() => {
    if (prompt?.value3) {
      setValue3(prompt?.value3 || '');
    }
  }, [prompt?.value3]);

  const {
    buttonStyle = {},
    button2Style = {},
    button3Style = {},
    buttonTextStyle = {},
    button2TextStyle = {},
    button3TextStyle = {},
    zIndex = 12000000,
  } = prompt;

  // console.log({button3TextStyle});
  return (
    <Modal
      open={prompt?.open || false}
      zIndex={zIndex}
      handleClose={() => setPrompt({open: false})}
      style={prompt.style || {}}
      backgroundStyle={prompt.backgroundStyle}>
      <View
        style={{
          width: '100%',
          minHeight: 200,
          backgroundColor: 'white',
          padding: 10,
          paddingBottom: 20,
          display: 'flex',
          alignItems: 'center',
        }}>
        <BodyText
          text={prompt.titleText}
          textStyle={{textAlign: 'center', fontSize: 18, fontWeight: '700'}}
        />
        <BodyText text={prompt.text} textStyle={{textAlign: 'center'}} />
        {!!prompt.bullets &&
          prompt.bullets.map?.((bullet) => {
            return (
              <BodyText
                text={'•   ' + bullet}
                textStyle={{
                  textAlign: 'left',
                  width: '80%',
                  ...(prompt.bulletStyle || {}),
                }}
              />
            );
          })}
        {!prompt.hideInput && (
          <TextInput
            placeholder={prompt.placeholder || prompt.buttonText || 'Input'}
            style={{
              height: 35,
              fontFamily: global.font.circular,
              textAlign: 'center',
              minWidth: 300,
              fontSize: 14,
              borderWidth: 1.5,
              borderStyle: 'solid',
              borderColor: '#a7a8aa',
              maxWidth: 300,
              marginTop: prompt.bullets ? 40 : 20,
              marginBottom: 20,
            }}
            value={value || ''}
            onChangeText={(text) => {
              setValue(text);
            }}
          />
        )}

        {prompt.inputOptions && (
          <DropDown
            id="DropDown-prompt-input"
            style={{width: 380, height: 40}}
            value={value || ''}
            data={prompt.inputOptions}
            handleChange={(newValue) => {
              if (prompt.inputOptionsEffect) {
                prompt.inputOptionsEffect(newValue);
              }
              console.log('change detected', newValue);
              setValue(newValue);
            }}
          />
        )}
        {prompt.inputOptions2 && (
          <DropDown
            id="DropDown-prompt-input"
            style={{width: 380, height: 40, marginTop: 20}}
            value={value2 || ''}
            data={prompt.inputOptions2}
            handleChange={(newValue) => {
              console.log('change detected', newValue);
              setValue2(newValue);
            }}
          />
        )}
        {prompt.inputOptions3 && (
          <DropDown
            id="DropDown-prompt-input"
            style={{width: 380, height: 40}}
            value={value3 || ''}
            data={prompt.inputOptions3}
            handleChange={(newValue) => {
              console.log('change detected', newValue);
              setValue3(newValue);
            }}
          />
        )}

        <ContinueButton
          text={prompt.buttonText}
          containerStyle={{
            width: 300,
            marginTop: !prompt.hideInput ? 15 : 30,
            ...buttonStyle,
          }}
          textStyle={buttonTextStyle}
          handlePress={() => {
            if (!value && !prompt.hideInput && !prompt.noTextWarning) {
              return alert(
                prompt.noTextWarning || 'Please enter text before submitting',
              );
            }
            prompt.handlePress(value, value2, value3);
          }}
        />
        {!!prompt.button2Text && (
          <ContinueButton
            text={prompt.button2Text}
            containerStyle={{
              width: 300,
              marginTop: 15,
              backgroundColor: global.color.teal,
              ...button2Style,
            }}
            textStyle={button2TextStyle}
            handlePress={() => {
              if (!value && !prompt.hideInput && !prompt.noTextWarning) {
                return alert(
                  prompt.noTextWarning || 'Please enter text before submitting',
                );
              }
              if (prompt.handlePress2) {
                prompt.handlePress2(value);
              } else {
                setPrompt({open: false});
              }
            }}
          />
        )}
        {!!prompt.button3Text && (
          <ContinueButton
            text={prompt.button3Text}
            containerStyle={{
              width: 300,
              marginTop: 15,
              backgroundColor: global.color.teal,
              ...button3Style,
            }}
            textStyle={button3TextStyle}
            handlePress={() => {
              if (!value && !prompt.hideInput) {
                return alert(
                  prompt.noTextWarning || 'Please enter text before submitting',
                );
              }
              prompt.handlePress3(value);
            }}
          />
        )}
        {!!prompt.checkBox && !prompt.hideInput && (
          <View
            style={{
              height: 30,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 10,
              maxWidth: '70%',
            }}>
            <input
              name="isGoing"
              type="checkbox"
              checked={!value4}
              onChange={(event) => {
                const target = event.target;
                console.log({target});
                setValue4(!target.checked);
              }}
            />
            <Text style={{marginLeft: 5}}>{prompt?.checkBoxLabel}</Text>
          </View>
        )}
      </View>
    </Modal>
  );
}
