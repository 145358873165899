import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import {useUserState} from '../Providers/UserProvider';
import AdminPage from '../Nav/AdminPage';
import Card from './Card';
import {useModalState} from '../Providers/ModalProvider';
import Link from 'next/link';
import {useMediaQuery} from '../Providers/MediaProvider';
import {useRouter} from 'next/router';

export default function RequireAuth({children}) {
  const route = useRouter();
  const {myUserId, myCompanyUUID} = useUserState();
  const {setLoginModalOpen} = useModalState();
  const {h1Style} = useMediaQuery();
  var requiresFurtherLogin = !myUserId;
  if (myUserId && route.pathname?.includes('profile/create')) {
    requiresFurtherLogin = false;
  }
  console.log('RequireAuth', {myUserId, requiresFurtherLogin});
  if (requiresFurtherLogin) {
    return (
      <AdminPage data={1} maxContentWidth={1100} hideSeo>
        <Card>
          <Text style={h1Style}></Text>
          <Text style={{fontFamily: global.font.circular}}>
            Please{' '}
            <TouchableOpacity
              onPress={() => {
                console.log('opening modal');
                setLoginModalOpen({open: true});
              }}
              style={{color: 'blue', textDecoration: 'underline'}}>
              <Text> log in</Text>
            </TouchableOpacity>{' '}
            to create new job posts as an admin. If you believe you are trying
            to apply to a job please go to{' '}
            <Link
              style={{color: 'blue', textDecoration: 'underline'}}
              href="https://www.letsroam.com/careers">
              our career page
            </Link>
            .
          </Text>
        </Card>
      </AdminPage>
    );
  }
  return <>{children}</>;
}
