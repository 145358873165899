import moment from 'moment';
const fetchWithParams = async (url, params) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(params),
    });

    if (response.status < 300) {
      return await response.json();
    } else {
      console.log('api fetch call failed', {url});
    }
  } catch (error) {
    console.log({error});
    try {
    } catch (err) {
      console.log(err);
    }
  }
};

function debounce(func, timeout = 300) {
  const currentTime = moment.now();
  if (!window.debounceTime) {
    window.debounceTime = currentTime;
    return (...args) => func.apply(this, args);
  } else if (currentTime - window.debounceTime < timeout) {
    window.debounceTime = currentTime;
    // no fire
  } else {
    window.debounceTime = currentTime;
    return (...args) => func.apply(this, args);
  }
}

export {fetchWithParams, debounce};
