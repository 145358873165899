import {Tooltip} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import Icon from 'src/Common/Icon';
import useShiftState from 'src/Providers/useShiftState';

import {useMediaQuery} from '../Providers/MediaProvider';
import {useSafeNavigationProvider} from '../Providers/SafeNavigationProvider';

const PrettyButton = ({
  to,
  title,
  shiftTitle,
  icon,
  handlePress,
  style = {},
  boxShadow,
  shiftTip,
}) => {
  const {isDesktop, bodyStyle} = useMediaQuery();
  const {safeNavigate} = useSafeNavigationProvider();

  const [shiftHeld] = useShiftState();

  const Wrapper = shiftTip ? Tooltip : View;

  return (
    <Wrapper title={shiftTip} arrow placement="top">
      <TouchableOpacity
        onPress={() => {
          if (to) {
            return safeNavigate(to);
          }
          handlePress(shiftHeld);
        }}
        style={{
          textDecorationLine: 'none',
          color: global.color.gray3,
          poiner: 'cursor',
          borderRadius: 100,
          backgroundColor: '#EFF5F9',
          padding: 8,
          paddingLeft: 12,
          paddingRight: 12,
          marginLeft: 2,
          marginRight: isDesktop ? 2 : 0,
          marginBottom: isDesktop ? 0 : 10,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          boxShadow: boxShadow ? '0 2px 2px -1px rgba(0,0,0,.3)' : 'none',
          ...style,
        }}>
        <Icon
          name={icon}
          style={{
            marginRight: 10,
            height: isDesktop ? 16 : 12,
            width: isDesktop ? 16 : 12,
            color: global.color.gray3,
          }}
        />
        <Text style={{...bodyStyle, fontSize: isDesktop ? 14 : 12}}>
          {shiftHeld && shiftTitle ? shiftTitle : title}
        </Text>
      </TouchableOpacity>
    </Wrapper>
  );
};

export default PrettyButton;
