import React, {Fragment} from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import Link from 'next/link';
import UserIcon from '../Common/UserIcon';
import {useMediaQuery} from '../Providers/MediaProvider';
import {useModalState} from '../Providers/ModalProvider';
import {useUserState} from '../Providers/UserProvider';
import {useSafeNavigationProvider} from '../Providers/SafeNavigationProvider';
import {useRouter} from 'next/router';

export default function TopNavBar({showAdmin = false, whiteHeader = false}) {
  const {userSQL, firebaseSignOut, myUserId} = useUserState();
  const {setLoginModalOpen} = useModalState();

  const isAdmin = userSQL?.email?.includes('@letsroam.com');
  const router = useRouter();
  console.log({router});
  return (
    <View
      style={{
        display: 'flex',
        width: '100%',
        backgroundColor: whiteHeader ? 'white' : global.color.gray3,
        height: 60,
        boxShadow: '0 2px 2px -2px rgba(0,0,0,.5)',
        zIndex: 10,
        alignItems: 'center',
        paddingLeft: 20,
        paddingRight: 20,
        justifyContent: 'space-between',
        flexDirection: 'row',
      }}>
      <Link href={isAdmin || showAdmin ? '/client/jobs' : '/'}>
        <Image
          source={'/images/Lets_Roam_logo_horizontal.png'}
          style={{height: 40, width: 120, objectFit: 'contain'}}></Image>
      </Link>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {!router?.pathname?.includes('/client/') &&
          !router?.pathname?.includes('/apply/') && (
            <Fragment>
              <NavLink
                to={'/pricing'}
                text={'Pricing'}
                whiteHeader={whiteHeader}
              />
              <NavLink
                to={'/features'}
                text={'Features'}
                whiteHeader={whiteHeader}
              />
              {/* <NavLink
              to={'/contact'}
              text={'Contact'}
              whiteHeader={whiteHeader}
            /> */}
              {!myUserId && (
                <NavLink
                  handlePress={() => {
                    setLoginModalOpen(true);
                  }}
                  text={'Login'}
                  whiteHeader={whiteHeader}
                  isLast
                />
              )}
              {!!myUserId && (
                <NavLink
                  handlePress={() => firebaseSignOut()}
                  text={'Logout'}
                  whiteHeader={whiteHeader}
                  isLast
                />
              )}
              {/* <NavLink to={'/applicants'} text={'Applicants'} /> */}
            </Fragment>
          )}
        {router?.pathname?.includes('/client/') && (
          <Fragment>
            <NavLink
              to={'/client/jobs'}
              text={'Jobs'}
              whiteHeader={whiteHeader}
            />
            {userSQL?.email?.includes('@letsroam.') && (
              <NavLink
                to={'/client/templates'}
                text={'Templates'}
                whiteHeader={whiteHeader}
              />
            )}

            <NavLink
              to={'/client/profile/edit'}
              text={'Company Profile'}
              whiteHeader={whiteHeader}
            />
            {!!myUserId && (
              <NavLink
                handlePress={() => firebaseSignOut()}
                text={'Sign Out'}
                whiteHeader={whiteHeader}
              />
            )}
            {/* <NavLink to={'/applicants'} text={'Applicants'} /> */}
          </Fragment>
        )}
        {(showAdmin || isAdmin || !router?.pathname?.includes('apply')) &&
          !!userSQL &&
          userSQL?.user_id && (
            <UserIcon
              whiteHeader={whiteHeader}
              handlePress={() => {
                console.log('user icon pressed', {userSQL});
                if (!userSQL) {
                  setLoginModalOpen({open: true});
                }
              }}
            />
          )}
      </View>
    </View>
  );
}

const NavLink = ({
  to,
  handlePress,
  text,
  whiteHeader = false,
  isLast = false,
}) => {
  const {safeNavigate} = useSafeNavigationProvider();
  const {bodyStyle} = useMediaQuery();
  return (
    <TouchableOpacity
      onPress={() => {
        if (to) {
          return safeNavigate(to);
        }
        handlePress();
      }}
      style={{
        textDecorationLine: 'none',
        textDecorationColor: 'none',
        borderRight: isLast
          ? 'none'
          : whiteHeader
          ? '2px solid ' + global.color.gray1
          : '2px solid white',
        padding: 5,
        paddingLeft: 20,
        paddingRight: 20,
      }}>
      <View>
        <Text
          style={{
            ...bodyStyle,
            fontSize: 14,
            color: whiteHeader ? global.color.gray3 : 'white',
          }}>
          {text}
        </Text>
      </View>
    </TouchableOpacity>
  );
};
