import React from 'react';
import {
  Text,
  TouchableOpacity,
  Platform,
  View,
  ActivityIndicator,
  Linking,
  Image,
} from 'react-native';
import {useMediaQuery} from '../Providers/MediaProvider';

export const ALink = (props) => {
  const {children, style = {}} = props;
  if (Platform.OS !== 'web' || !props.href) {
    return (
      <TouchableOpacity
        onPress={() => {
          if (props.href) {
            Linking.openURL(props.href);
          } else if (props.onPress) {
            props.onPress();
          }
        }}
        style={{textDecoration: 'none', ...style}}>
        {children}
      </TouchableOpacity>
    );
  } else {
    return (
      <a
        href={props.href}
        target="_blank"
        title={props.title}
        style={{textDecoration: 'none', ...style}}>
        {children}
      </a>
    );
  }
};

export const BodyText = (props) => {
  const {
    children,
    text = '',
    textStyle = {},
    color = global.color.gray3,
    largeText = false,
    center = false,
    textAlign,
    header = false,
    nativeID = undefined,
  } = props;
  return (
    <Text
      nativeID={nativeID}
      style={{
        color: color,
        fontFamily: global.font.circular,
        fontSize: largeText ? 20 : 16,
        textAlign: textAlign || (center ? 'center' : 'left'),
        marginTop: 10,
        marginHorizontal: 15,
        ...(header ? {textAlign: 'center', fontSize: 20, lineHeight: 22} : {}),
        ...textStyle,
      }}>
      {children || text || ''}
    </Text>
  );
};

export const ContinueButton = (props) => {
  const {isDesktop} = useMediaQuery();
  const {
    text,
    handlePress,
    background,
    knockout,
    loading,
    width,
    height = 40,
    textStyle = {},
    containerStyle,
    menu = false,
    renderElement = true,
    icon,
    nativeID,
  } = props;
  const styles = knockout
    ? {
        backgroundColor: 'transparent',
        borderWidth: 2,
        borderColor: background || global.color.orange,
        borderRadius: 5,
        ...containerStyle,
      }
    : {
        backgroundColor: background || global.color.orange,
        borderRadius: 5,
        ...containerStyle,
      };

  if (!renderElement) {
    return <View />;
  }

  const textColor = knockout
    ? background || global.color.orange
    : global.color.white;

  return (
    <TouchableOpacity
      style={{
        alignItems: 'center',
        flex: 1,
        width: menu ? 180 : width,
        height: 40,
        minHeight: height || 40,
        maxHeight: height,
        justifyContent: 'center',
        position: 'relative',
        marginHorizontal: 5,
        marginVertical: 5,
        flexDirection: 'row',
        paddingHorizontal: isDesktop ? 30 : 10,
        paddingVertical: isDesktop ? 8 : 4,
        // @ts-ignore
        pointerEvents: 'all',
        letterSpacing: 1.5,
        textTransform: 'uppercase',
        fontFamily: global.font.gothic,
        ...styles,
        containerStyle,
      }}
      activeOpacity={0.9}
      // @ts-ignore
      nativeID={nativeID}
      onPress={handlePress}
      disabled={loading || !handlePress}>
      {!!loading ? (
        <ActivityIndicator size={'small'} color={textColor} />
      ) : (
        <Text
          style={{
            color: textColor,
            fontSize: isDesktop ? 16 : 14,
            fontFamily: global.font.circular,
            fontWeight: '600',
            textAlign: 'center',
            marginRight: !!icon ? 4 : 0,
            ...textStyle,
          }}>
          {text}
        </Text>
      )}
      {/* {!!icon && (
        <SimpleLineIcon
          name={icon}
          size={10}
          color={global.color.gray3_50}
          style={{right: 1, top: -5}}
        />
      )} */}
    </TouchableOpacity>
  );
};

export const ImageButton = (props) => {
  const {
    handlePress,
    source,
    buttonStyle = {},
    imageStyle = {},
    textStyle = {},
    text,
    renderElement = true,
    filter,
  } = props;
  if (!renderElement) {
    return <View />;
  }

  return (
    <TouchableOpacity
      style={{
        position: 'relative',
        ...buttonStyle,
      }}
      onPress={handlePress}
      activeOpacity={0.9}>
      <img src={source} style={{width: 60, height: 60, ...imageStyle}} />
      {!filter && (
        <Text style={{fontFamily: global.font.circular, ...textStyle}}>
          {text}
        </Text>
      )}
      {!!filter && (
        <View
          style={{
            position: 'absolute',
            backgroundColor: filter,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}>
          <Text style={{fontFamily: global.font.circular, ...textStyle}}>
            {text}
          </Text>
        </View>
      )}
    </TouchableOpacity>
  );
};
