import React, {useState, useEffect, useCallback} from 'react';
import {useModalState} from '../Providers/ModalProvider';
import {PaymentElement} from '@stripe/react-stripe-js';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';

import Modal from '../Common/Modal';
import {LockClosedIcon} from '@heroicons/react/outline';
import {Image} from 'react-native-web';
import {adminApiCall} from 'src/Helpers/apiCalls';
import {useUserState} from 'src/Providers/UserProvider';
import {useRouter} from 'next/router';

function StripeModal({type}) {
  const {stripeModalOpen, setStripeModalOpen} = useModalState();
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: 'https://my-site.com/order/123/complete',
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <Modal
      style={{maxWidth: 500}}
      open={stripeModalOpen}
      handleClose={() => setStripeModalOpen(false)}>
      <PaymentForm type={type} />
    </Modal>
  );
}

export default StripeModal;

const PaymentForm = ({type}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState();
  const router = useRouter();
  const {userSQL, myUserId, company} = useUserState();
  const options = {
    style: {
      base: {
        fontSize: 16,
        color: '#424770',
        letterSpacing: '0.025em',
        padding: 10,
        fontFamily: 'Source Code Pro, monospace',
        border: '1px solid gray',
        borderStyle: 'solid',
        borderWidth: '1px',
        '::placeholder': {
          color: '#aab7c4',

          height: 20,
          borderColor: global.color.gray3,
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const payload = stripe
      .createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
      })
      .then((payload) => {
        console.log('[PaymentMethod]', payload);
        if (payload.error) {
          alert('Payment failed: ' + payload?.error?.message);
        } else {
          adminApiCall('apiHandleSale', {
            payload,
            name,
            type,
            myUserId,
            trial_period_days: !company.has_used_free_trial ? 14 : 0,
            email: userSQL?.email,
            first_name: userSQL?.first_name,
            last_name: userSQL?.last_name,
          })
            .then((payload) => {
              console.log('[PaymentMethod]', payload);
              if (payload.error) {
                alert('Payment failed: ' + payload?.error?.message);
              } else {
                adminApiCall('apiHandleSale', {
                  payload,
                  name,
                });
                alert(
                  !company.has_used_free_trial
                    ? 'Congragulations, you have started your 14 day free trial.'
                    : 'Congragulations, you have changed your plan!',
                );
                window.location.reload();
              }
            })
            .catch((e) => {
              console.log('[PaymentMethodFailed]', e);
              alert('Payment Failed, Please Contact Support');
            });
        }
      })
      .catch((e) => {
        console.log('[PaymentMethodFailed]', e);
        alert('Payment Failed');
      });
  };

  const total = '';

  var cta =
    type == 'starter' || type == 'growth'
      ? 'Start Free Trial'
      : 'Set Payment Method';

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-12">
        <div className="grid grid-cols-12 gap-y-6 gap-x-4">
          <div className="col-span-full">
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 20,
              }}>
              <Image
                source={'/images/Lets_Roam_Logo_horizontal.png'}
                style={{
                  height: 40,
                  width: 105,
                  objectFit: 'contain',
                }}></Image>
            </div>
            <label
              htmlFor="name-on-card"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="block text-sm font-medium text-gray-700">
              Name on card
            </label>
            <div className="mt-1">
              <input
                type="text"
                id="name-on-card"
                name="name-on-card"
                autoComplete="cc-name"
                style={{borderStyle: 'solid', borderWidth: '1px', height: 20}}
                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="col-span-full">
            <label
              htmlFor="card-number"
              className="block text-sm font-medium text-gray-700">
              Card number
            </label>
            <div className="mt-1">
              <CardNumberElement
                options={options}
                onReady={() => {
                  console.log('CardNumberElement [ready]');
                }}
                onChange={(event) => {
                  console.log('CardNumberElement [change]', event);
                }}
                onBlur={() => {
                  console.log('CardNumberElement [blur]');
                }}
                onFocus={() => {
                  console.log('CardNumberElement [focus]');
                }}
                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="col-span-8 sm:col-span-9">
            <label
              htmlFor="expiration-date"
              className="block text-sm font-medium text-gray-700">
              Expiration date (MM/YY)
            </label>
            <div className="mt-1">
              <CardExpiryElement
                options={options}
                onReady={() => {
                  console.log('CardNumberElement [ready]');
                }}
                onChange={(event) => {
                  console.log('CardNumberElement [change]', event);
                }}
                onBlur={() => {
                  console.log('CardNumberElement [blur]');
                }}
                onFocus={() => {
                  console.log('CardNumberElement [focus]');
                }}
                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="col-span-4 sm:col-span-3">
            <label
              htmlFor="cvc"
              className="block text-sm font-medium text-gray-700">
              CVC
            </label>
            <div className="mt-1">
              <CardCvcElement
                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                options={options}
                onReady={() => {
                  console.log('CardNumberElement [ready]');
                }}
                onChange={(event) => {
                  console.log('CardNumberElement [change]', event);
                }}
                onBlur={() => {
                  console.log('CardNumberElement [blur]');
                }}
                onFocus={() => {
                  console.log('CardNumberElement [focus]');
                }}
              />
            </div>
          </div>
        </div>

        <div className="mt-6 flex space-x-2">
          <div className="flex items-center h-5">
            <input
              id="same-as-shipping"
              name="same-as-shipping"
              type="checkbox"
              defaultChecked
              className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
            />
          </div>
          <label
            htmlFor="same-as-shipping"
            className="text-sm font-medium text-gray-900">
            Billing address is the same as shipping address
          </label>
        </div>

        <button
          type="submit"
          className="w-full mt-6 bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          {cta}
        </button>

        <p className="flex justify-center text-sm font-medium text-gray-500 mt-6 h-margin__b--20">
          <LockClosedIcon
            className="w-5 h-5 text-gray-400 mr-1.5"
            aria-hidden="true"
          />
          Checkout Secured By Stripe
        </p>
      </div>
    </form>
  );
};
