import React, {useState} from 'react';

import {View, Text, Alert} from 'react-native';

// @ts-ignore
import Modal from '../Common/Modal';
import {ContinueButton} from '../Common/Text';
import {useModalState} from '../Providers/ModalProvider';
import {useMediaQuery} from '../Providers/MediaProvider';
import GenericEditSection from '../Common/GenericEditSection';
import {getJobFields} from '../Helpers/dataHelpers';
import {adminApiCall} from '../Helpers/apiCalls';
import {useUserState} from '../Providers/UserProvider';

import {useJobsProvider} from '../Providers/JobsProvider';
import {useSafeNavigationProvider} from '../Providers/SafeNavigationProvider';

export default function NewJobModal() {
  const {newJobModalOpen, setNewJobModalOpen} = useModalState();
  const {h1Style, isDesktop} = useMediaQuery();
  const [newJob, setJobData] = useState({});
  const {jobTemplates} = useJobsProvider();
  const {firebaseIdToken, myCompanyUUID, myUserId} = useUserState();
  const {unSafeNavigate} = useSafeNavigationProvider();

  console.log({myCompanyUUID});

  const handleSaveJob = () => {
    console.log('handleSaveJob', {
      firebaseIdToken,
      myUserId,
      myCompanyUUID,
      newJob,
    });
    if (!newJob?.title) {
      return alert('A title is required.');
    } else if (!newJob?.description) {
      return alert('A description is required.');
    } else if (!myCompanyUUID) {
      return alert(
        'No company UUID found. Please contact support at 407-808-9625.',
      );
    }
    // return console.log('checks doen');
    adminApiCall('apiAdminCreateNewJob', {
      firebaseIdToken,
      myUserId,
      myCompanyUUID,
      newJob,
    }).then((saveData) => {
      console.log({saveData});
      if (saveData.redirectURL) {
        unSafeNavigate(saveData.redirectURL);
        setNewJobModalOpen(false);
      }
      alert('Success: new job created. Make sure to publish it!');
      window.location.reload();
    });
  };

  return (
    <Modal
      open={newJobModalOpen}
      handleClose={() => setNewJobModalOpen(false)}
      style={{
        height: isDesktop ? '100%' : '100vh',
        width: '100%',
        maxWidth: '100%',
        borderRadius: isDesktop ? 30 : 0,
      }}
      backgroundStyle={{height: isDesktop ? '100%' : '100vh'}}>
      <View
        nativeID="NewJobModal"
        style={{
          maxWidth: '100vw',
          height: isDesktop ? '100%' : '100vh',
          overflow: 'scroll',
        }}>
        <View>
          <div style={{padding: 10}}>
            <h1 className="text-5xl leading-6 font-medium text-gray-900 h-padding__t--10">
              Creating New Job
            </h1>
            <p className="mt-1 text-2xl text-gray-500 h-margin__t--20">
              We are creating a new job for you. This form will create a job
              according to our pre-built templates, along with the relevant
              stages and questions. You can create your own templates in the
              templates section.
            </p>
          </div>
          <GenericEditSection
            data={newJob}
            editFields={getJobFields(true, jobTemplates)}
            handleDataChange={(newJobData) => {
              console.log({newJobData});
              setJobData(newJobData);
            }}
          />
          <ContinueButton
            containerStyle={{marginBottom: 100}}
            text={'Create New Job'}
            handlePress={handleSaveJob}
          />
        </View>
      </View>
    </Modal>
  );
}
