import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';

import {useRouter} from 'next/router';

export const StateContext = createContext(null);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function SafeApplicantProvider(props) {
  console.log('Provider Update: UserProvider');
  const router = useRouter();
  // adding the event state info

  const [unsavedData, setUnsavedData] = useState(false);

  const navigate = (url) => {
    router.push(url);
  };

  const safeNavigate = useCallback(
    async (url, handleSaveData) => {
      console.log('safeNavigation', {unsavedData, url, handleSaveData});
      // unsaved data with way yo save
      if (unsavedData && handleSaveData) {
        if (
          window.confirm(
            'There is unsaved data are, do you want to save before you navigate?',
          )
        ) {
          await handleSaveData();
          navigate(url);
        } else {
          navigate(url);
        }
        // unsaved data with no way yo save
      } else if (unsavedData) {
        if (
          window.confirm(
            'WARNING: You are about to lose unsaved data. Are you sure you want to navigate.',
          )
        ) {
          navigate(url);
        }
        // no unsaved data, just navigate
      } else {
        navigate(url);
      }
    },
    [unsavedData],
  );

  const contextValue = {
    unsavedData,
    setUnsavedData,
    safeNavigate,
    unSafeNavigate: navigate,
  };

  return (
    <StateContext.Provider value={{...contextValue}}>
      {props.children}
    </StateContext.Provider>
  );
}

export function useSafeNavigationProvider() {
  const context = useContext(StateContext);
  useEffect(() => {
    if (!window.state) {
      window.state = {};
    }
    window.state.SafeNavigationProvider = context;
  }, [context]);
  if (!context) {
    return {};
  }
  return context;
}
