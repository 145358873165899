import React from 'react';
import {Text} from 'react-native';

const makeTextBold = (text, searchText) => {
  if (!searchText) return [text];
  const regex = new RegExp(`(${searchText})`, 'gi');
  return text.split(regex);
};

const BoldMatchingText = ({text = '', searchText = '', style = {}}) => {
  const textParts = makeTextBold(text, searchText);

  return (
    <Text style={{...style}}>
      {textParts.map((part, index) =>
        part.toLowerCase() === searchText.toLowerCase() ? (
          <strong key={index}>{part}</strong>
        ) : (
          part
        ),
      )}
    </Text>
  );
};

export default BoldMatchingText;
