import React, {createContext, useContext, useState, useMemo} from 'react';
import {useEffect} from 'react';
import {Platform} from 'react-native-web';
import {getLocalData, saveLocalData} from 'src/Helpers/localStorage';

export const StateContext = createContext(null);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function MediaProvider(props) {
  console.log('Provider Update: UserProvider');
  // adding the event state info

  const [isDesktop, setIsDesktop] = useState(
    // window.matchMedia(`(min-width: 1000px)`)?.matches,
    getLocalData('isDesktop') === '1' ? 1 : 0,
    1,
  );

  const [isLargeScreen, setIsLargeScreen] = useState(
    // window.matchMedia(`(min-width: 1000px)`)?.matches,
    getLocalData('isLargeScreen') === '1' ? 1 : 0,
    1,
  );

  const [isSmallScreen, setIsSmallScreen] = useState(
    // window.matchMedia(`(max-width: 600px)`)?.matches,
    getLocalData('isSmallScreen') === '1' ? 1 : 0,
    0,
  );

  console.log('media provider update', {
    isSmallScreen,
    isLargeScreen,
    isDesktop,
  });

  useEffect(() => {
    console.log('media provider mounted');
    const desktop = window.matchMedia(`(min-width: 1000px)`);
    const large = window.matchMedia(`(min-width: 1600px)`);
    const small = window.matchMedia(`(max-width: 600px)`);

    if (desktop?.matches !== isDesktop) {
      setIsDesktop(desktop?.matches);
    }
    if (small?.matches !== isSmallScreen) {
      setIsSmallScreen(small?.matches);
    }

    if (large?.matches !== isLargeScreen) {
      setIsLargeScreen(large?.matches);
    }

    console.log({desktop, small, large});
    desktop.addEventListener('change', (change) => {
      console.log({change});
      const {matches} = change;
      console.log(matches ? 'Width is Desktop' : 'Width is Mobile');
      setIsDesktop(matches);
    });
    small.addEventListener('change', (change) => {
      console.log({change});
      const {matches} = change;
      console.log(matches ? 'Width is Small Screen' : 'Width is Large Screen');
      setIsSmallScreen(matches);
    });
    large.addEventListener('change', (change) => {
      console.log({change});
      const {matches} = change;
      console.log(
        matches ? 'Width is Lagre Screen' : 'Width is Not Large Screen',
      );
      setIsLargeScreen(matches);
    });
  }, [isDesktop, isSmallScreen, isLargeScreen]);

  useEffect(() => {
    saveLocalData('isDesktop', isDesktop);
    saveLocalData('isSmallScreen', isSmallScreen);
    saveLocalData('isLargeScreen', isLargeScreen);
  }, [isDesktop, isSmallScreen, isLargeScreen]);

  const h1Style = {
    fontSize: isDesktop ? 32 : 24,
    marginBottom: 10,
    fontWeight: 'bold',
    fontFamily: 'Circular',
  };

  const h2Style = {
    fontSize: isDesktop ? 24 : 20,
    marginBottom: 10,
    fontWeight: 'bold',
    fontFamily: 'Circular',
  };

  const h3Style = {
    fontSize: isDesktop ? 18 : 16,
    marginBottom: 10,
    fontFamily: 'Circular',
  };

  const bodyStyle = {
    fontSize: isDesktop ? 16 : 14,
    marginBottom: 0,
    fontFamily: 'Circular',
  };

  console.log({isDesktop});

  const contextValue = {
    isDesktop,
    h1Style,
    h2Style,
    h3Style,
    isSmallScreen,
    bodyStyle,
    isLargeScreen,
  };

  return (
    <StateContext.Provider value={{...contextValue}}>
      {props.children}
    </StateContext.Provider>
  );
}

export function useMediaQuery() {
  const context = useContext(StateContext);
  useEffect(() => {
    if (!window.state) {
      window.state = {};
    }
    window.state.MediaProvider = context;
  }, [context]);
  if (!context) {
    return {
      isDesktop: null,
      h1Style: null,
      h2Style: null,
      h3Style: null,
    };
  }
  return context;
}
