import React, {useEffect} from 'react';
import {Fragment} from 'react';

import {View, TouchableOpacity, StyleSheet, Platform} from 'react-native';
import {useModalState} from '../Providers/ModalProvider';

export default function Modal({
  children = false,
  open = false,
  handleClose = false,
  style = {},
  backgroundStyle = {},
  zIndex = 1200,
  showInLine = false,
}) {
  const {isDesktop} = useModalState();
  useEffect(() => {
    if (document.querySelector('iframe') && !showInLine) {
      console.log('changing modal display', {open, showInLine});
      document.querySelector('iframe').style.display = open ? 'none' : 'block';
    }
  }, [open]);
  if (showInLine) {
    return <Fragment>{children}</Fragment>;
  }
  if (!open) {
    console.log('Modal is not open');
    return <View />;
  }
  console.log('Modal is open');

  return (
    <View
      // @ts-ignore
      nativeID={'OpenModal'}
      // @ts-ignore
      style={{
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        backgroundColor: global.color.black_80,
        zIndex,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        height:
          // @ts-ignore
          isDesktop ? '100vh' : '100%',
        ...backgroundStyle,
      }}>
      <TouchableOpacity
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
        }}
        onPress={() => {
          if (typeof handleClose === 'function') {
            handleClose(false);
          }
        }}
      />
      <View
        style={{
          width: isDesktop ? 500 : '90%',
          // height: '100%',
          maxHeight: isDesktop ? 900 : '90%',
          backgroundColor: 'white',
          zIndex: 1500,
          position: 'relative',
          overflow: 'hidden',
          borderRadius: 30,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',

          ...style,
        }}>
        {children}
      </View>
    </View>
  );
}
