import React from 'react';
import {NextSeo} from 'next-seo';
import {useJobsProvider} from 'src/Providers/JobsProvider';
import {useRouter} from 'next/router';

const SeoHeader = () => {
  const router = useRouter();
  const {asPath} = router;
  console.log({asPath});
  const {applicant_uuid = null, stage_uuid} = router.query;
  const {applicants} = useJobsProvider();

  const {job, stages} = useJobsProvider();

  const selectedStage = stages?.find?.((stage) => {
    return stage.uuid === stage_uuid;
  });

  var title = `LH`;
  if (asPath == '/client/jobs') {
    title = 'LH | All Jobs';
  } else if (asPath?.includes('edit_job')) {
    title = `LH | Edit Job ${job?.title?.replace("Let's Roam", '')?.trim()}`;
  } else if (asPath?.includes('view_all_canidates')) {
    title = `LH | View All Candidates ${job?.title
      ?.replace("Let's Roam", '')
      ?.trim()}`;
  } else if (asPath?.includes('edit_stage')) {
    title = `LH | Edit Stage ${selectedStage?.title
      ?.replace("Let's Roam", '')
      ?.trim()} ${job?.title?.replace("Let's Roam", '')?.trim()}`;
  } else if (applicants) {
    var selectedApplicant = applicants?.find((applicant) => {
      return applicant.uuid === applicant_uuid;
    });
    title = `LH | ${selectedApplicant?.first_name || ''} ${
      selectedApplicant?.last_name || ''
    } ${job?.title?.replace("Let's Roam", '')?.trim()}`;
  }

  return (
    <NextSeo
      title={title}
      description="Let's Hire is the easiest way to hire people!"
    />
  );
};

export default SeoHeader;
