import {useRouter} from 'next/router';
import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';

import PrettyButton from '../Common/PrettyButton';
import {ContinueButton} from '../Common/Text';
import {useMediaQuery} from '../Providers/MediaProvider';
import {useSafeNavigationProvider} from '../Providers/SafeNavigationProvider';

export default function TopBar({
  title = '',
  subtitle,
  cta = null,
  menuItems,
  selectedMenuUUID = '',
  maxContentWidth = 1100,
  handlePress = () => {},
  prettyButtons,
}) {
  const {h1Style, h3Style, isDesktop, bodyStyle} = useMediaQuery();
  const {safeNavigate} = useSafeNavigationProvider();
  const route = useRouter();
  return (
    <View
      style={{
        width: '100%',
        boxShadow:
          '0 1px 5px -1px rgb(51 62 73 / 14%), 0 4px 10px 0 rgb(51 62 73 / 8%)',
        backgroundColor: 'white',
        alignItems: 'center',
        display: 'flex',
      }}>
      <View
        style={{
          width: '100%',
          maxWidth: maxContentWidth,

          minHeight: (isDesktop ? 80 : 60) + (subtitle ? 10 : 0),

          paddingLeft: 20,
          paddingRight: 20,
          flex: 1,
          paddingTop: 5,
        }}>
        <View>
          <View
            style={{
              justifyContent: 'space-between',
              flexDirection: isDesktop ? 'row' : 'column',
              display: 'flex',
              alignItems: 'center',
            }}>
            <View>
              <Text style={{...h1Style, flex: 1, marginBottom: 0}}>
                {title}
              </Text>
              {!!subtitle && (
                <Text
                  style={{...h3Style, flex: 1, marginBottom: 0, marginTop: 10}}>
                  {subtitle}
                </Text>
              )}
            </View>
            {!!prettyButtons && (
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: isDesktop ? 0 : 20,
                }}>
                {prettyButtons?.map?.(
                  ({to, title, icon, handlePress}, index) => {
                    return (
                      <PrettyButton
                        key={`${title} ${index}`}
                        to={to}
                        title={title}
                        handlePress={handlePress}
                        icon={icon}
                        boxShadow
                        style={{marginLeft: 10}}
                      />
                    );
                  },
                )}
              </View>
            )}
            {!!cta && (
              <ContinueButton
                handlePress={handlePress}
                text={cta}
                containerStyle={{maxWidth: 300}}
              />
            )}
          </View>

          {menuItems && (
            <View>
              <View
                style={{
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  width: isDesktop ? '100%' : 'unset',
                  marginTop: 10,
                  // flexWrap: 'wrap',
                  overflow: 'scroll',
                }}>
                {menuItems?.map?.((item, index) => {
                  const lastIndex = menuItems.length - 1;
                  const isLast = lastIndex === index;
                  // console.log({isLast, count: lastIndex, index});
                  const isEditing = route.pathname?.includes('edit');
                  const link = `/client/job/${item.job_uuid}/${item.uuid}/${
                    isEditing ? 'edit' : 'canidates'
                  }`;
                  const selected = item.uuid === selectedMenuUUID;
                  return (
                    <TouchableOpacity
                      onPress={() => {
                        safeNavigate(link);
                      }}
                      key={`TopBar Item ${item.uuid}`}
                      style={{
                        color: global.color.gray3,
                        textDecorationLine: 'none',
                        flex: 1,
                        minWidth: isDesktop ? 70 : 100,
                      }}>
                      <View
                        style={{
                          // paddingHorizontal: 20,
                          marginBottom: isDesktop ? 20 : 5,
                          textAlign: 'center',
                          padding: 5,
                          borderLeft:
                            index === 0
                              ? `1px solid ${global.color.gray3}`
                              : null,
                          borderRight: `1px solid ${global.color.gray3}`,
                          minWidth: isDesktop ? 70 : 100,
                        }}>
                        <View
                          style={{
                            backgroundColor: selected
                              ? global.color.gray1
                              : null,
                            borderRadius: 8,
                            padding: 5,
                            paddingHorizontal: 10,
                          }}>
                          <Text
                            style={{
                              ...bodyStyle,
                              fontWeight: 'bold',
                              fontSize: isDesktop ? 14 : 10,
                              color: global.color.gray3,
                            }}>
                            {item.applicant_count || 0}{' '}
                            {item.title?.replace(' Interview', '')}
                          </Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  );
                })}
              </View>
            </View>
          )}
        </View>
      </View>
    </View>
  );
}
