import * as React from 'react';
import Head from 'next/head';

import {Platform, View} from 'react-native';

// @ts-ignore
import {initializeApp} from 'firebase/app';

import MediaProvider from '../Providers/MediaProvider';
import UserProvider from '../Providers/UserProvider';
import JobsProvider from '../Providers/JobsProvider';

import SafeNavigationProvider from '../Providers/SafeNavigationProvider';
import ModalProvider from '../Providers/ModalProvider';
import 'src/global.js';
import Modals from 'src/Modals/Modals';
import {GlobalStyle} from 'src/Elements/Website/GlobalStyles';

import 'styles/globals.css';
import SeoHeader from 'src/Common/SeoHeader';
import {useRouter} from 'next/router';
import RequireAuth from 'src/Common/RequireAuth';

import {QueryClient, QueryClientProvider} from 'react-query';

// import AppErrorWrapper from '../AppErrorWrapper';

// if (apps?.length === 0 && Platform.OS === 'web') {
initializeApp({
  apiKey: 'AIzaSyDjD6UKKJTVPgkIzqeCZZpua2a1Quo8fac',
  authDomain: 'letshire-29352.firebaseapp.com',
  databaseURL: 'https://letshire-29352-default-rtdb.firebaseio.com',
  projectId: 'letshire-29352',
  storageBucket: 'letshire-29352.appspot.com',
  messagingSenderId: '417268687918',
  appId: '1:417268687918:web:c14fe5aee5c0985d1f6bbf',
  measurementId: 'G-437XQVW574',
});
// }
const queryClient = new QueryClient();
// declare global {
//   interface Window {
//     global: any;
//   }
// }
console.disableYellowBox = true;

function MyApp({Component = {}, pageProps = {}}) {
  const router = useRouter();
  var Wrapper = View;
  if (router?.pathname?.includes('client')) {
    Wrapper = RequireAuth;
  }

  global.cookies = pageProps?.serverCookies;

  console.log('MainAppWrapper', {
    OS: Platform.OS,
  });

  console.log({pageProps});

  React.useEffect(() => {
    console.log('deleting extra cookies');
    // Get all cookies
    var cookies = document.cookie.split(';');

    for (var i = 0; i < cookies.length; i++) {
      var key = cookies[i].split('=')[0].trim();

      // Check if the cookie's key starts with the given prefix
      if (key.startsWith('ey')) {
        console.log('deleting cookie: ', key);
        // "Delete" the cookie
        document.cookie =
          key + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      }
    }
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <View
        nativeID="MainAppWrapper"
        style={{
          height: Platform.OS == 'web' ? '100vh' : '100%',
          maxWidth: '100vw',
        }}>
        {/* <AppErrorWrapper> */}

        <UserProvider
          {...pageProps}
          key={`UserProvider-${pageProps.serverData?.requestTime}`}>
          <View style={{flex: 1, height: '100%', position: 'relative'}}>
            <View style={{height: Platform.OS == 'web' ? '100vh' : '100%'}}>
              <View style={{flex: 1, height: '100%', position: 'relative'}}>
                <SafeNavigationProvider>
                  <MediaProvider>
                    <JobsProvider
                      {...pageProps}
                      key={`JobsProvider-${pageProps.serverData?.requestTime}`}>
                      <ModalProvider>
                        <Wrapper>
                          <QueryClientProvider client={queryClient}>
                            <SeoHeader {...pageProps} />
                            <Component {...pageProps} />
                          </QueryClientProvider>
                        </Wrapper>
                        <Modals />
                      </ModalProvider>
                    </JobsProvider>
                  </MediaProvider>
                </SafeNavigationProvider>
              </View>
            </View>
          </View>
        </UserProvider>

        <GlobalStyle />
      </View>
    </>
  );
}

export default MyApp;

// overriden by other pages
// nice defualt
export async function getInitialProps(context) {
  const {req, res} = context;
  const {cookies} = req;
  console.log({cookies});
  global.cookies = cookies;

  console.log('getInitialProps');

  return {
    props: {serverCookies: cookies}, // will be passed to the page component as props
  };
}
