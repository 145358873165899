import {getCookie, deleteCookie, setCookie} from 'cookies-next';

export const saveLocalData = (index = 'error', data = null) => {
  if (!data || data == 'null') {
    deleteCookie(index);
  }
  const formattedData = typeof data == 'object' ? JSON.stringify(data) : data;
  console.log('setting cookie', {index, formattedData});
  setCookie(index, formattedData);
  // console.trace();
};

export const getLocalData = (index) => {
  // console.log('getLocalData', {index, cookies: global?.cookies});
  console.log('getLocalData', {index});
  var data = getCookie(index) || global.cookies?.[index];
  if (data == 'null') {
    data = null;
  }
  // console.log('getLocalData', {index, data, global});
  try {
    const formattedData = JSON.parse(data);
    console.log({formattedData});
    return formattedData;
  } catch (e) {
    // console.log({e});
    const formattedData = data;
    console.log({formattedData});
    return formattedData;
  }
};

export const clearAllCookies = () => {
  // Get all cookies as a single string
  const cookies = document.cookie.split(';');

  // Loop through each cookie and delete it
  cookies.forEach((cookie) => {
    const cookieName = cookie.split('=')[0].trim();
    // Set the cookie's expiration date to the past to delete it
    document.cookie =
      cookieName + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
  });

  console.log('All cookies have been cleared');
};
