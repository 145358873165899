import React, {useRef} from 'react';
import {Text, TouchableOpacity, View, Image} from 'react-native';
import {uploadFile} from '../Helpers/Firebase/firebase';
import {v4 as uuid} from 'uuid';
import Card from './Card';
import Dropdown from '../Common/Dropdown';
import Icon from 'src/Common/Icon';
import {useMediaQuery} from '../Providers/MediaProvider';
import {useEffect} from 'react';
import ToolTip from './Tooltip';
import {useSafeNavigationProvider} from '../Providers/SafeNavigationProvider';
import DebouncedInput from 'src/Common/DebouncedInput';

const GenericEditSection = ({
  data = {},
  editFields = [],
  handleDataChange = () => console.log('no data change handler'),
  index,
  isLast,
  handleDeleteQuestion,
  moveQuestion,
  addQuestionAtIndex,
  displayIndex,
  handleDuplicateQuestion,
  handleEditPress,
}) => {
  const {isDesktop, isSmallScreen} = useMediaQuery();
  const {unsavedData, setUnsavedData} = useSafeNavigationProvider();
  const inputRef = useRef('inherit');

  // setting unset dropdowns to first value
  // on component did mount
  useEffect(() => {
    const newData = {...data};
    console.log({newData});
    var wasEdited = false;
    editFields?.map?.((editField, index) => {
      const {dropdownOptions, type, field, fallback} = editField;
      var dataValue = data?.[field] || fallback || '';
      if (
        dropdownOptions &&
        dropdownOptions?.[0]?.value &&
        type === 'dropdown'
      ) {
        wasEdited = true;
        newData[field] = dropdownOptions?.[0]?.value || '';
      }
    });
    if (wasEdited) {
      console.log('GenericEditSection: fixing empty data fields ', {
        newData,
      });

      handleDataChange(newData);
    }
  }, []);

  return (
    <Card>
      <View>
        {editFields?.map?.((editField) => {
          const {
            type = 'fill_in_the_blank',
            placeholder = 'Type Here',
            label = '',
            field = 'error',
            dropdownOptions = false,
            labelIndex = false,
            getShouldDisplay,
            fallback = null,
            additionalInfo = false,
            maxLength,
            unit,
            disabled = false,
            autoComplete,
          } = editField;
          var dataValue = data?.[field] || fallback || '';
          if (Array.isArray(dataValue) && !dataValue?.[0]) {
            dataValue = fallback || [];
          }
          const displayIndexText = labelIndex ? displayIndex : '';
          if (getShouldDisplay) {
            // console.log('checking if should display', {getShouldDisplay});
            const shouldDisplay = getShouldDisplay(data);
            if (!shouldDisplay) {
              return (
                <View key={`GenericEditSection ${index} ${label} ${field}`} />
              );
            }
          }
          return (
            <View
              key={`GenericEditSection ${index} ${label} ${field}`}
              style={{
                display: 'flex',
                width: '100%',
                flexDirection:
                  !isDesktop && type === 'multiple_choice' ? 'column' : 'row',
                marginTop: 10,
                flexWrap: !isSmallScreen ? 'no-wrap' : 'wrap',
              }}>
              {1 && (
                <ToolTip tip={additionalInfo}>
                  <Text
                    style={{
                      fontSize: 18,
                      fontWeight: 'bold',
                      marginRight: 20,
                      width: 250,
                      marginTop: isDesktop ? 0 : 10,
                      marginBottom: isSmallScreen ? 10 : 0,
                    }}>
                    {label}
                    {displayIndexText}:
                    {!!additionalInfo && (
                      <View
                        style={{
                          backgroundColor: global.color.gray2,
                          borderRadius: '50%',
                          padding: 4,
                          paddingBottom: 0,
                          marginLeft: 3,
                          marginBottom: 2,
                        }}>
                        <Icon
                          name="faQuestion"
                          style={{
                            height: isDesktop ? 10 : 10,
                            width: isDesktop ? 10 : 10,
                            color: global.color.white,
                          }}
                        />
                      </View>
                    )}
                  </Text>
                </ToolTip>
              )}

              {(type === 'fill_in_the_blank' || type === 'long_form_input') && (
                <DebouncedInput
                  style={{
                    width: '100%',
                    height: 40,
                    fontSize: 14,
                    border: `1px solid ${global.color.gray1}`,

                    marginBottom: 10,
                    padding: type === 'long_form_input' ? 10 : 0,
                    paddingLeft: 10,
                    borderRadius: type === 'long_form_input' ? 5 : 0,
                    backgroundColor: disabled ? global.color.gray1_50 : null,
                    minHeight: type === 'long_form_input' ? 100 : null,
                  }}
                  inputRef={inputRef}
                  autoComplete={autoComplete}
                  maxLength={maxLength}
                  disabled={disabled}
                  onChangeText={(newValue) => {
                    const newData = {...data};
                    // console.log({field, newValue});
                    newData[field] = newValue;
                    !unsavedData && setUnsavedData(true);
                    handleDataChange(newData);
                  }}
                  multiline={type === 'long_form_input'}
                  value={dataValue}
                  placeholder={'Type Here'}
                />
              )}
              {type === 'dropdown' && (
                <Dropdown
                  value={dataValue}
                  data={dropdownOptions}
                  style={{width: '100%', paddingLeft: 10}}
                  handleChange={(newValue) => {
                    var newData = {...data};

                    const selectedDropDownOption = dropdownOptions?.find(
                      (option) => option?.value == newValue,
                    );
                    if (selectedDropDownOption?.changeTemplate) {
                      newData = {
                        ...newData,
                        ...selectedDropDownOption?.changeTemplate,
                      };
                    }
                    console.log({selectedDropDownOption});
                    // console.log({field, newValue});
                    newData[field] = newValue;
                    !unsavedData && setUnsavedData(true);
                    handleDataChange(newData);
                  }}
                />
              )}
              {type === 'multiple_choice' && (
                <MultipleChoiceQuestionCreate
                  values={dataValue}
                  handleChange={(newValue) => {
                    const newData = {...data};
                    console.log({field, newValue});
                    newData[field] = newValue;
                    !unsavedData && setUnsavedData(true);
                    handleDataChange(newData);
                  }}
                />
              )}
              {type === 'data_link' && (
                <TouchableOpacity
                  onPress={() => {
                    handleEditPress(index);
                  }}
                  style={{
                    fontFamily: global.font.circular,
                    textDecoration: 'underline',
                    marginTop: 10,
                    fontSize: 18,
                  }}>
                  <Text style={{fontFamily: global.font.circular}}>
                    {dataValue || 0} {unit}
                  </Text>
                </TouchableOpacity>
              )}
              {type === 'photo_upload' && (
                <View
                  style={{
                    position: 'relative',
                    border: `1px solid ${global.color.gray3}`,

                    padding: 5,
                    pointer: 'cursor',
                    flex: !!dataValue ? '1' : 'unset',
                    width: '100%',
                  }}>
                  {!dataValue && (
                    <Text style={{fontFamily: global.font.circular}}>
                      <Icon
                        name="faUpload"
                        style={{
                          height: isDesktop ? 15 : 14,
                          width: isDesktop ? 15 : 14,
                          color: global.color.gray3,
                        }}
                      />
                      <Text
                        style={{
                          fontFamily: global.font.circular,
                          marginLeft: 10,
                          color: global.color.gray3,
                        }}>
                        Add Photo
                      </Text>
                    </Text>
                  )}
                  {!!dataValue && (
                    <img
                      src={dataValue}
                      style={{height: 150, flex: 1, objectFit: 'cover'}}
                    />
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    style={{
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      opacity: 0,
                    }}
                    // value={selectedFile}
                    onChange={async (e) => {
                      // Create a root reference
                      const file = e.target.files[0];
                      console.log({file});
                      if (!file.type?.includes('image')) {
                        e.preventDefault();
                        e.target.value = null;
                        return alert('Please uplaod only an image');
                      }

                      const downloadURL = await uploadFile(
                        `jobData/photoUpload/${uuid()}`,
                        file,
                      );
                      const newData = {...data};
                      // console.log({field, newValue});
                      newData[field] = downloadURL;
                      !unsavedData && setUnsavedData(true);
                      handleDataChange(newData);
                    }}
                  />
                  {!!dataValue && (
                    <TouchableOpacity
                      onPress={() => {
                        const newData = {...data};
                        // console.log({field, newValue});
                        newData[field] = null;
                        !unsavedData && setUnsavedData(true);
                        handleDataChange(newData);
                      }}
                      style={{
                        position: 'absolute',
                        right: 5,
                        top: 10,
                        zIndex: 1000,
                      }}>
                      <Icon
                        name="faTrash"
                        style={{
                          height: isDesktop ? 15 : 14,
                          width: isDesktop ? 15 : 14,
                          color: global.color.gray3,
                        }}
                      />
                    </TouchableOpacity>
                  )}
                </View>
              )}
            </View>
          );
        })}
      </View>
      {!!moveQuestion && index !== 0 && (
        <TouchableOpacity
          onPress={() => {
            moveQuestion(index, -1);
          }}
          style={{
            position: 'absolute',
            right: isDesktop ? -18 : -18,
            top: 0,
          }}>
          <Icon
            name="faArrowUp"
            style={{
              height: isDesktop ? 15 : 14,
              width: isDesktop ? 15 : 14,
              color: global.color.gray3,
            }}
          />
        </TouchableOpacity>
      )}
      {!!moveQuestion && !isLast && (
        <TouchableOpacity
          onPress={() => {
            moveQuestion(index, 1);
          }}
          style={{
            position: 'absolute',
            right: isDesktop ? -18 : -18,
            top: 40,
          }}>
          <Icon
            name="faArrowDown"
            style={{
              height: isDesktop ? 15 : 14,
              width: isDesktop ? 15 : 14,
              color: global.color.gray3,
            }}
          />
        </TouchableOpacity>
      )}
      {!!addQuestionAtIndex && (
        <TouchableOpacity
          onPress={() => {
            if (
              window.confirm(
                'Are you sure you want to add an additional item above?',
              )
            ) {
              addQuestionAtIndex();
            }
          }}
          style={{
            position: 'absolute',
            right: -18,
            top: 80,
          }}>
          <Icon
            name="faPlus"
            style={{
              height: isDesktop ? 15 : 14,
              width: isDesktop ? 15 : 14,
              color: global.color.gray3,
            }}
          />
        </TouchableOpacity>
      )}
      {!!handleDuplicateQuestion && (
        <TouchableOpacity
          onPress={() => {
            if (
              window.confirm(
                'Are you sure you want to duplicate this item below?',
              )
            ) {
              handleDuplicateQuestion();
            }
          }}
          style={{
            position: 'absolute',
            right: isDesktop ? -18 : -18,
            top: 120,
          }}>
          <Icon
            name="faClone"
            style={{
              height: isDesktop ? 15 : 14,
              width: isDesktop ? 15 : 14,
              color: global.color.gray3,
            }}
          />
        </TouchableOpacity>
      )}
      {!!handleDeleteQuestion && (
        <TouchableOpacity
          onPress={() => {
            if (
              window.confirm('Are you sure you want to delete this question')
            ) {
              handleDeleteQuestion();
            }
          }}
          style={{
            position: 'absolute',
            right: isDesktop ? -18 : -18,
            top: 160,
          }}>
          <Icon
            name="faTrash"
            style={{
              height: isDesktop ? 15 : 14,
              width: isDesktop ? 15 : 14,
              color: global.color.gray3,
            }}
          />
        </TouchableOpacity>
      )}

      {!!handleEditPress && (
        <TouchableOpacity
          onPress={() => {
            handleEditPress(index);
          }}
          style={{
            position: 'absolute',
            right: isDesktop ? -18 : -18,
            top: 200,
          }}>
          <Icon
            name="faEdit"
            style={{
              height: isDesktop ? 15 : 14,
              width: isDesktop ? 15 : 14,
              color: global.color.gray3,
            }}
          />
        </TouchableOpacity>
      )}
    </Card>
  );
};

const MultipleChoiceQuestionCreate = ({values, index, handleChange}) => {
  const {isDesktop} = useMediaQuery();
  console.log({values});
  return (
    <View>
      <View
        style={{
          display: 'flex',
          width: isDesktop ? 600 : '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 10,
        }}>
        <Text style={{fontFamily: global.font.circular, flex: 1}}>Option</Text>
        <ToolTip>
          <Text tip={'Only matters if the question has a score.'}>
            Is Correct
          </Text>
        </ToolTip>
      </View>
      <View>
        {values?.map?.((option, challengeIndex) => {
          return (
            <View
              key={`Multiple Choice ${option}  ${index} ${challengeIndex}`}
              style={{
                display: 'flex',
                width: isDesktop ? 600 : '100%',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 10,
              }}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flex: 1,
                }}>
                <DebouncedInput
                  style={{
                    width: isDesktop ? 600 : '60%',
                    height: 30,
                    fontSize: 14,
                    border: `1px solid ${global.color.gray1}`,
                    padding: 2,
                    marginLeft: 0,
                    borderRadius: 0,
                    flex: 1,
                  }}
                  onChangeText={(newText) => {
                    const newValues = [...values];
                    console.log({newValues, challengeIndex});
                    newValues[challengeIndex]['option'] = newText;
                    handleChange(newValues);
                  }}
                  multiline={false}
                  value={option.option}
                  placeholder={'Type Here'}
                />
                <TouchableOpacity
                  onPress={() => {
                    const newValues = [...values];
                    newValues.splice(challengeIndex, 1);
                    handleChange(newValues);
                  }}
                  style={{
                    marginTop: 5,
                    marginBottom: 10,
                    marginLeft: 10,
                  }}>
                  <View
                    style={{
                      height: 20,
                      width: 20,

                      padding: 1,
                    }}>
                    <Icon
                      color={'blue'}
                      name={'faTrash'}
                      style={{
                        width: 18,
                        height: 20,
                        objectFit: 'contain',
                      }}
                    />
                  </View>
                </TouchableOpacity>
              </View>
              <TouchableOpacity
                onPress={() => {
                  const newValues = [...values];
                  newValues[challengeIndex]['correct'] =
                    !newValues[challengeIndex]['correct'];
                  handleChange(newValues);
                }}
                style={{
                  marginTop: 5,
                  marginBottom: 10,
                  minWidth: 100,
                  display: 'flex',
                  alignItems: 'flex-end',
                }}>
                <View
                  style={{
                    height: 20,
                    width: 20,
                    border: `1px solid ${global.color.gray1}`,
                    // backgroundColor: selected ? global.color.gray3 : null,placeholder
                    padding: 1,
                    backgroundColor: option?.correct ? 'blue' : 'white',
                  }}>
                  {!!option?.correct && (
                    <Text
                      style={{
                        fontFamily: global.font.circular,
                        color: 'white',
                      }}>
                      ✓
                    </Text>
                  )}
                </View>
              </TouchableOpacity>
            </View>
          );
        })}
      </View>
      <TouchableOpacity
        onPress={() => {
          const newValues = [...values];
          newValues.push({option: 'New Option', correct: 0});
          handleChange(newValues);
        }}
        style={{
          marginTop: 5,
          marginBottom: 10,
        }}>
        <Text style={{fontFamily: global.font.circular}}>+ Add Option</Text>
      </TouchableOpacity>
    </View>
  );
};

export default GenericEditSection;

export const validateForm = (editFields, data) => {
  console.log('validateForm', {editFields, data});
  const validation = {isValid: 1, errors: [], errorText: ''};
  editFields.map?.((editField, index) => {
    const {label, field, fallback, notRequired = false} = editField;
    var dataValue = data?.[field] || fallback || '';
    validation.isValid = 1;
    if (!dataValue && !notRequired) {
      validation.isValid = 0;
      const newError = `\n${label} is required`;
      validation.errors.push(newError);
      validation.errorText += ' ' + newError;
    }
  });
  return validation;
};
