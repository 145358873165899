import React, {Component, Fragment, useState} from 'react';

import {View, Text, TouchableOpacity, StyleSheet, Image} from 'react-native';
import {useUserState} from '../Providers/UserProvider';

const signInImage = 'images/SignIn/signInImage.png';

export default function UserIcon(props) {
  const {whiteHeader} = props;

  const {userSQL} = useUserState();
  const [error, setError] = useState();

  const image_source = userSQL?.photoUrl;

  const getUserStatus = () => {
    const hasImageSource = userSQL?.photoUrl;

    if (!!userSQL && hasImageSource) {
      return 'profile';
    }

    if (!!userSQL && error) {
      return 'error';
    }

    if (!!userSQL) {
      return 'color';
    }

    return 'noUser';
  };

  const userPortrait = () => {
    let imageSource = {uri: false};
    if (userSQL) {
      if (userSQL?.customPhotoUrl) {
        imageSource = {uri: userSQL?.customPhotoUrl};
      } else if (userSQL.photoUrl) {
        imageSource = {uri: userSQL?.photoUrl};
      }
    }
    return (
      <View
        style={{
          // width: 120,
          marginTop: -5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingBottom: 10,
        }}>
        <img
          style={{...styles.image}}
          src={imageSource}
          onError={() => setError(true)}
        />
        <Text
          style={{
            color: whiteHeader ? global.color.gray3 : 'white',
            fontSize: 12,
            fontFamily: global.font.circular,
          }}>
          {userSQL?.first_name}
        </Text>
      </View>
    );
  };

  const colorPortrait = () => {
    const backgroundColor = userSQL
      ? (userSQL && userSQL?.photoColor) || global.color.blue
      : global.color.blue;

    return (
      <View
        style={{
          ...styles.image,

          backgroundColor,
          position: 'absolute',
          top: 0,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Text
          style={{
            color: whiteHeader ? global.color.gray3 : 'white',
            fontSize: 20,
            fontFamily: global.font.circular,
          }}>
          {userSQL?.first_name?.charAt(0).toUpperCase()}
        </Text>
      </View>
    );
  };

  const genericPortrait = () => (
    <View
      style={{
        // width: 120,
        marginTop: -5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: 10,
      }}>
      <img
        style={{...styles.image}}
        src={signInImage}
        onError={() => setError(true)}
      />
      <Text
        style={{
          color: whiteHeader ? global.color.gray3 : 'white',
          fontSize: 12,
          fontFamily: global.font.circular,
        }}>
        Login
      </Text>
    </View>
  );

  const handlePress = () => {
    window.location.href = '/client/jobs';
  };

  // console.log({error});

  const renderOptions = {
    profile: userPortrait,
    color: colorPortrait,
    noUser: genericPortrait,
    error: colorPortrait,
  };

  const userStatus = getUserStatus();
  // console.log({userStatus, userSQL});

  return (
    <View style={{flexDirection: 'row', width: 60, alignItems: 'center'}}>
      <TouchableOpacity
        style={styles.container}
        onPress={() => {
          console.log('User avatar pressed');
          handlePress();
        }}>
        {/* User Icon */}
        <View
          style={{
            position: 'relative',
            width: 40,
            height: 36,
            borderWidth: 0,
            marginLeft: -2,
            marginTop: 4,
          }}>
          {error && !!userSQL
            ? renderOptions['error']()
            : renderOptions[userStatus]()}
        </View>
      </TouchableOpacity>
    </View>
  );
}

const styles = {
  container: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: 10,
    position: 'relative',
    height: 50,
    width: 50,
  },
  image: {
    height: 34,
    width: 34,
    borderRadius: 17,
  },
};
