import React from 'react';
import {Text, TouchableOpacity} from 'react-native';
import {Linking} from 'react-native';

const TechSupportButton = (props) => {
  return (
    <TouchableOpacity
      onPress={() => {
        alert(
          'We are sorry that you had issues with your application. This is a new platform and we are sorry for any inconviences. Our dev team will work quickly to fix any issues.',
        );
        Linking.openURL(
          `mailto:hring@letsroam.com?subject=Interview tech Issues&body=Dear Brant,%0D%0A%0D%0AI have been having issues applying for a job at Let's Roam, but had some technical issues. %0D%0A%0D%0A Here is an outline of what went wrong:%0D%0A%0D%0A %0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0ADebug Information%0D%0APage: ${window.location.href}%0D%0AMy User Agent: ${window.navigator.userAgent}.`,
        );
      }}
      style={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        padding: 5,
        backgroundColor: global.color.orange,
      }}>
      <Text style={{fontSize: 10, color: 'white'}}>Report A Tech Issue</Text>
    </TouchableOpacity>
  );
};

export default TechSupportButton;
