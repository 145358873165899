import React, {createContext, useContext, useState, useEffect} from 'react';

export const StateContext = createContext(null);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function ModalProvider(props) {
  console.log('Provider Update: UserProvider');
  // adding the event state info
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [prompt, setPrompt] = useState(false);
  const [newJobModalOpen, setNewJobModalOpen] = useState(false);
  const [stripeModalOpen, setStripeModalOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  const contextValue = {
    loginModalOpen,
    setLoginModalOpen,
    prompt,
    setPrompt,
    newJobModalOpen,
    setNewJobModalOpen,
    stripeModalOpen,
    setStripeModalOpen,
    searchOpen,
    setSearchOpen,
  };

  return (
    <StateContext.Provider value={{...contextValue}}>
      {props.children}
    </StateContext.Provider>
  );
}

export function useModalState() {
  const context = useContext(StateContext);
  useEffect(() => {
    if (!window.state) {
      window.state = {};
    }
    window.state.ModalProvider = context;
  }, [context]);
  if (!context) {
    return {
      loginModalOpen: null,
      setLoginModalOpen: null,
      prompt: null,
      setPrompt: null,
      newJobModalOpen: null,
      setNewJobModalOpen: null,
      stripeModalOpen: null,
      setStripeModalOpen: null,
      searchOpen: null,
      setSearchOpen: null,
    };
  }
  return context;
}
