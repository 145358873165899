import React, {useState, useEffect, useCallback} from 'react';

// @ts-ignore
import LoginModalPhoneInput from './LoginModalPhoneInput';
import {isValidPhoneNumber} from 'react-phone-number-input';
import {RecaptchaVerifier, getAuth} from 'firebase/auth';
import {useUserState, useSetUserState} from '../Providers/UserProvider';
import {useModalState} from '../Providers/ModalProvider';
import {
  View,
  Platform,
  Text,
  TouchableOpacity,
  StyleSheet,
  TextInput,
} from 'react-native';
import {ContinueButton, BodyText} from '../Common/Text';
import Modal from '../Common/Modal';
import {useMediaQuery} from '../Providers/MediaProvider';
import {tryPasswordReset} from 'src/Helpers/loginHelpers';
import {useRouter} from 'next/router';
// @ts-ignore
// import {getAuth} from '../Firebase/firebase';

const GoogleLogo = 'images/SignIn/google_logo.png';
const TextLogo = 'images/SignIn/text_login.png';
const FacebookLogo = 'images/SignIn/facebook_logo.png';

const styles = StyleSheet.create({
  paper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 10,
    marginTop: 20,
    backgroundColor: 'rgb(242, 242, 242)',
    color: 'black',
    minHeight: 280,
  },
});

function LoginModal() {
  const auth = getAuth();
  const {signIn, user, disabledNewsletter, setDisabledNewsletter} =
    useUserState();

  const {
    loginModalOpen,
    setLoginModalOpen = () => null,
    setPrompt,
  } = useModalState();
  const {isDesktop, bodyStyle} = useMediaQuery();
  const router = useRouter();

  const [showTextLogin, setShowTextLogin] = useState(false);

  const [showVerificationCode, setShowVerificationCode] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationId, setVerificationId] = useState({});

  // resetting everything after sign in
  useEffect(() => {
    if (user && setLoginModalOpen) {
      setLoginModalOpen(false);
      setShowVerificationCode(false);
      setEmail('');
      setPhone('');
      setName('');
      setVerificationCode('');
    }
  }, [user]);

  useEffect(() => {
    if (showTextLogin) {
      if (
        isDesktop &&
        // @ts-ignore
        !window.recaptchaVerifier
      ) {
        //@ts-ignore
        window.recaptchaVerifier = new auth.RecaptchaVerifier(
          'textInfoSubmit',
          {
            size: 'invisible',
            callback: (response) => {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              console.log({response});
            },
          },
        );
      }
    }
    return () => {
      // @ts-ignore
      window.recaptchaVerifier = null;
    };
  }, [showTextLogin]);

  const login = (passcode, extraData = {}) => {
    signIn(passcode, extraData)
      .then(() => {})
      .catch((err) => {
        if (err && JSON.stringify(err) != '{}') {
          setPrompt({
            open: true,
            titleText: 'Error Logging In',
            text: JSON.stringify(err),
            buttonText: 'Okay',
            backgroundStyle: {backgroundColor: 'rgba(0, 0,0, 0.7)'},
            hideInput: true,
            handlePress: (text) => {
              setPrompt({open: false});
            },
          });
        }
      });
  };

  const validatePhoneNumber = useCallback(() => {
    console.log({phone, email, name});
    if (!isValidPhoneNumber(phone)) {
      return setPrompt({
        open: true,
        titleText: 'Additional Info Needed',
        text: 'Please enter a valid phone number',
        buttonText: 'Okay',
        backgroundStyle: {backgroundColor: 'rgba(0, 0,0, 0.7)'},
        hideInput: true,
        handlePress: (text) => {
          setPrompt({open: false});
        },
      });
    } else if (!email || !/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
      return setPrompt({
        open: true,
        titleText: 'Additional Info Needed',
        text: 'Please enter a valid email address',
        buttonText: 'Okay',
        backgroundStyle: {backgroundColor: 'rgba(0, 0,0, 0.7)'},
        hideInput: true,
        handlePress: (text) => {
          setPrompt({open: false});
        },
      });
    } else if (!name) {
      return setPrompt({
        open: true,
        titleText: 'Additional Info Needed',
        text: 'Please enter your name!',
        buttonText: 'Okay',
        backgroundStyle: {backgroundColor: 'rgba(0, 0,0, 0.7)'},
        hideInput: true,
        handlePress: (text) => {
          setPrompt({open: false});
        },
      });
    } else {
      console.log({
        log: 'trying text number validation',
        phone,
        // @ts-ignore
        verifor: window.recaptchaVerifier,
      });
      getAuth()
        // @ts-ignore
        .signInWithPhoneNumber(phone, window.recaptchaVerifier)
        .then((verificationInfo) => {
          // setUser(user.user);
          console.log({verificationInfo});
          setVerificationId(verificationInfo.verificationId);
          setShowVerificationCode(true);
        })
        .catch((error) => {
          console.log({error});
          return setPrompt({
            open: true,
            titleText: 'Login Error',
            text: JSON.stringify(error),
            buttonText: 'Okay',
            zIndex: 99999,
            backgroundStyle: {backgroundColor: 'rgba(0, 0,0, 0.7)'},
            hideInput: true,
            handlePress: (text) => {
              setPrompt({open: false});
            },
          });
        });
    }
  }, [phone, email, name]);

  return (
    <Modal
      style={{maxWidth: 500}}
      open={loginModalOpen}
      handleClose={() => setLoginModalOpen(false)}>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-20 w-auto"
            src={'/images/Lets_Roam_Logo_horizontal.png'}
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-5xl font-extrabold text-gray-900 h-margin__t--30">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            {!window.location.href.includes('hiring') && (
              <div className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text font-medium text-gray-700">
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text font-medium text-gray-700">
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      autoComplete="current-password"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text"
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-2 block text text-gray-900">
                      Remember me
                    </label>
                  </div>

                  <div className="text">
                    <a
                      href="#"
                      onClick={async () => {
                        await tryPasswordReset(email);
                        alert(
                          'A password reset email has been sent to this account if it exists.',
                        );
                      }}
                      className="font-medium text-indigo-600 hover:text-indigo-500">
                      Forgot your password?
                    </a>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    onClick={() => login('password', {email, password})}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow text font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Sign in
                  </button>
                  <button
                    type="submit"
                    onClick={() => login('password-create', {email, password})}
                    className="h-margin__t--10 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow text font-medium text-white bg-gray-500 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Create Account
                  </button>
                </div>
              </div>
            )}

            <div className="mt-6">
              {!window.location.href.includes('hiring') && (
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text">
                    <span className="px-2 bg-white text-gray-500">
                      Or continue with
                    </span>
                  </div>
                </div>
              )}

              <div
                className="mt-6 gap-3 "
                style={{flexDirection: 'row', display: 'flex'}}>
                {!window.location.href.includes('hiring') && (
                  <div style={{flex: 1}}>
                    <a
                      href="#"
                      onClick={() => login('facebook')}
                      className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow bg-white text font-medium text-gray-500 hover:bg-gray-50">
                      <span className="sr-only">Sign in with Facebook</span>
                      <svg
                        className="w-5 h-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20">
                        <path
                          fillRule="evenodd"
                          d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </a>
                  </div>
                )}

                <div style={{flex: 1}}>
                  <a
                    href="#"
                    onClick={() => login('google')}
                    className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow bg-white text font-medium text-gray-500 hover:bg-gray-50">
                    <span className="sr-only">Sign in with Google</span>
                    <svg
                      className="w-5 h-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 24 24">
                      <path d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default LoginModal;

// <View style={{display: 'flex', alignItems: 'center', flex: 1}}>
// <BodyText
//   textStyle={{
//     textAlign: 'center',
//     fontSize: 22,
//     marginTop: 15,
//     marginBottom: 10,
//   }}
//   text={
//     !showTextLogin
//       ? 'Log In Or Create An Account'
//       : !showVerificationCode
//       ? 'Enter In Your Info'
//       : 'Enter Your Verification Code'
//   }
// />
// <TouchableOpacity
//   onPress={() => {
//     if (showVerificationCode) {
//       setShowVerificationCode(false);
//     }
//     if (showTextLogin) {
//       setShowTextLogin(false);
//     } else {
//       setLoginModalOpen(false);
//     }
//   }}
//   style={{
//     position: 'absolute',
//     top: 12,
//     left: 20,
//   }}>
//   <Text style={{...bodyStyle, fontSize: 20, fontWeight: '900'}}>X</Text>
// </TouchableOpacity>
// <View style={{...styles.paper, paddingBottom: 40}}>
//   {!showTextLogin && !showVerificationCode && (
//     <View style={{flexDirection: 'column', display: 'flex'}}>
//       <ContinueButton
//         handlePress={() => login('google')}
//         background={'#dd4b39'}
//         containerStyle={{width: 220, marginTop: 10}}
//         text={'Google'}
//         icon={TextLogo}
//       />
//       {/* <ContinueButton
//         handlePress={() => setShowTextLogin(true)}
//         background={global.color.gray3}
//         containerStyle={{width: 220, marginTop: 10}}
//         text={'Text'}
//         icon={FacebookLogo}
//       />
//       <ContinueButton
//         handlePress={() => login('facebook')}
//         containerStyle={{width: 220, marginTop: 10}}
//         background={'#3B5998'}
//         text={'Facebook'}
//         icon={GoogleLogo}
//       /> */}
//     </View>
//   )}
//   {!!showTextLogin && !showVerificationCode && (
//     <View style={{flexDirection: 'column', display: 'flex'}}>
//       <LoginModalPhoneInput phone={phone} setPhone={setPhone} />
//       <TextInput
//         placeholder={'Your Full Name'}
//         style={{
//           height: 35,
//           textAlign: 'center',
//           minWidth: 220,
//           fontSize: 14,
//           marginTop: 10,
//           width: 270,
//           paddingLeft: 10,
//           fontFamily: global.font.circular,
//           padding: 4,
//           borderWidth: 1.5,
//           borderStyle: 'solid',
//           borderColor: '#a7a8aa',
//         }}
//         value={name}
//         onChangeText={(text) => {
//           setName(text);
//         }}
//       />

//       <TextInput
//         placeholder={'Your Email'}
//         style={{
//           height: 35,
//           textAlign: 'center',
//           minWidth: 220,
//           fontSize: 14,
//           marginTop: 10,
//           width: 270,
//           paddingLeft: 10,
//           fontFamily: global.font.circular,
//           padding: 4,
//           borderWidth: 1.5,
//           borderStyle: 'solid',
//           borderColor: '#a7a8aa',
//         }}
//         value={email || ''}
//         onChangeText={(text) => {
//           setEmail(text);
//         }}
//       />
//       <View nativeID={'textInfoSubmit'} />
//       <ContinueButton
//         handlePress={validatePhoneNumber}
//         background={'#dd4b39'}
//         containerStyle={{width: 260, marginTop: 10}}
//         text={'Sign in with Text'}
//         icon={TextLogo}
//       />
//     </View>
//   )}
//   {showVerificationCode && (
//     <View style={{flexDirection: 'column', display: 'flex'}}>
//       <TextInput
//         placeholder={'Enter Your Received Verification Code'}
//         style={{
//           height: 35,
//           textAlign: 'center',
//           width: 270,
//           paddingLeft: 10,
//           fontFamily: global.font.circular,
//           padding: 4,
//           borderWidth: 1.5,
//           borderStyle: 'solid',
//           borderColor: '#a7a8aa',
//         }}
//         value={verificationCode}
//         onChangeText={(text) => {
//           setVerificationCode(text);
//         }}
//       />
//       <ContinueButton
//         handlePress={() => {
//           const userData = {
//             email,
//             name,
//             phone,
//           };
//           if (global) {
//             global.phoneUserData = userData;
//           }

//           signIn('text', {
//             verificationCode,
//             verificationId,
//           });
//         }}
//         background={'#dd4b39'}
//         nativeID={'textInfoSubmit'}
//         containerStyle={{width: 260, marginTop: 10}}
//         text={'Submit Code'}
//         icon={TextLogo}
//       />
//     </View>
//   )}
//   {isDesktop && (
//     <View
//       style={{
//         position: 'absolute',
//         left: 0,
//         right: 0,
//         bottom: 0,
//         height: 30,
//         display: 'flex',
//         flexDirection: 'row',
//         justifyContent: 'center',
//       }}>
//       <input
//         name="isGoing"
//         type="checkbox"
//         checked={!disabledNewsletter}
//         onChange={(event) => {
//           const target = event.target;
//           console.log({target});
//           setDisabledNewsletter(!target.checked);
//         }}
//       />
//       <View style={{marginRight: 20}}>
//         <Text style={{fontFamily: global.font.circular}}>
//           Sign Up for Newsletter
//         </Text>
//       </View>
//     </View>
//   )}
// </View>
// </View>
