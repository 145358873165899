import React from 'react';
import {TextInput} from 'react-native';

export class DebouncedInput extends React.PureComponent {
  static defaultProps = {
    delayTimeout: 300,
    minLength: 3,
    onChangeText: undefined,
    value: undefined,
    inputRef: undefined,
  };

  timerId = null;

  constructor(props) {
    super(props);

    this.state = {
      value: props.value || '',
      inputHeight: 0,
    };
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      inputHeight: this.props?.inputRef?.current?.scrollHeight,
    });
  }

  resetTimer() {
    clearTimeout(this.timerId);
  }

  componentWillUnmount() {
    this.resetTimer();
  }

  notify = (value) => {
    const {onChangeText, minLength} = this.props;

    const valueToUpdate = value.length >= minLength ? value : '';

    onChangeText(valueToUpdate);
  };

  runTimeoutUpdate = (value) => {
    const {delayTimeout} = this.props;

    this.resetTimer();
    this.timerId = setTimeout(() => this.notify(value), delayTimeout);
  };

  onChangeText = (value) => {
    console.log({value});
    const {minLength} = this.props;

    const valueToUpdate = value.length >= minLength ? value : '';

    this.setState({value}, () => this.runTimeoutUpdate(valueToUpdate));
  };

  onBlur = () => {
    this.resetTimer();
    this.notify(this.state.value);
  };

  render() {
    const {onChangeText, inputRef, ...props} = this.props;
    const {value} = this.state;

    return (
      <TextInput
        {...props}
        className="'bg-gray-50 border border-gray-300 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'"
        onChangeText={this.onChangeText}
        onBlur={this.onBlur}
        value={value}
        ref={inputRef}
        style={{
          ...this.props.style,
          minHeight: this.state.inputHeight || 'inherit',
        }}
      />
    );
  }
}

export default DebouncedInput;
