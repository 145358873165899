import {
  getDatabase,
  ref,
  set,
  update,
  get,
  off,
  onValue,
} from 'firebase/database';
import {getAuth} from 'firebase/auth';
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from 'firebase/storage';

import {initializeApp} from 'firebase/app';
import {useEffect, useState} from 'react';

initializeApp({
  apiKey: 'AIzaSyDjD6UKKJTVPgkIzqeCZZpua2a1Quo8fac',
  authDomain: 'letshire-29352.firebaseapp.com',
  databaseURL: 'https://letshire-29352-default-rtdb.firebaseio.com',
  projectId: 'letshire-29352',
  storageBucket: 'letshire-29352.appspot.com',
  messagingSenderId: '417268687918',
  appId: '1:417268687918:web:c14fe5aee5c0985d1f6bbf',
  measurementId: 'G-437XQVW574',
});

// @ts-ignore
// const { database = {}, User  = {}, storage  = {}, auth  = {} } = {}
const db = getDatabase();
const storageDb = getStorage();

const getFirebaseData = async (ref) => {
  if (!ref) {
    return null;
  }
  const dataRef = ref(db, ref);
  const snapshot = await get(dataRef);

  const data = await snapshot.val();

  return data;
};

const setFirebaseData = async (path, data) => {
  if (!path) {
    return null;
  }
  console.log('setting firebase data', {path, data});
  const dataRef = ref(db, path);
  return await set(dataRef, data);
};

const useIsOnline = () => {
  const [isOnline, setIsOnline] = useState(null);

  useEffect(() => {
    const connectedRef = ref(db, '.info/connected');
    onValue(connectedRef, (snapshot) => {
      const isConnected = snapshot.val();
      setIsOnline(isConnected);
    });

    return () => {
      off(connectedRef);
    };
  }, []);

  return isOnline;
};

const updateFirebaseData = async (path, data) => {
  if (!path || !data) {
    return null;
  }
  console.log('updating firebase data', {path, data});
  const dataRef = ref(db, path);
  if (typeof data === 'object') {
    return await update(dataRef, data);
  } else {
    return await set(dataRef, data);
  }
};

const useFirebaseData = (path) => {
  const [firebaseData, setFirebaseData] = useState();
  useEffect(() => {
    const dataRef = ref(db, path);
    if (!!path) {
      console.log('creating data ref', {path});
      onValue(dataRef, async (snapshot) => {
        const newData = await snapshot.val();
        console.log({newData});
        setFirebaseData(newData);
      });
    }
    return () => {
      off(dataRef);
      setFirebaseData(null);
    };
  }, [path]);

  console.log({firebaseData});

  return firebaseData;
};

const uploadFile = async (ref, file) => {
  if (!ref) {
    return null;
  }
  console.log('uploading firebase file', {ref, file});
  const fileRef = storageRef(storageDb, ref);
  const snapShot = await uploadBytes(fileRef, file);
  const downloadURL = await getDownloadURL(snapShot.ref);
  console.log({downloadURL});
  return downloadURL;
};

export {
  getDatabase,
  getAuth,
  getFirebaseData,
  updateFirebaseData,
  setFirebaseData,
  db,
  uploadFile,
  useFirebaseData,
  useIsOnline,
};
