import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
  faHandsHelping,
  faEye,
  faSyncAlt,
  faUserPlus,
  faUser,
  faGrinBeam,
  faHandPointer,
  faTrophy,
  faCommentSlash,
  faComments,
  faTheaterMasks,
  faAward,
  faUserSecret,
  faEdit,
  faInfoCircle,
  faHourglassHalf,
  faThumbsUp,
  faCoins,
  faLaughBeam,
  faArrowAltCircleLeft,
  faCamera,
  faChevronDown,
  faChevronUp,
  faBuilding,
  faPhoneAlt,
  faVolumeMute,
  faCompressArrowsAlt,
  faExpand,
  faVideo,
  faVideoSlash,
  faMicrophone,
  faUndo,
  faTrash,
  faMicrophoneSlash,
  faBars,
  faPhone,
  faCheck,
  faTimes,
  faSignOutAlt,
  faPlus,
  faWifi,
  faBolt,
  faSmileWink,
  faLink,
  faMap,
  faSearchPlus,
  faComment,
  faFlag,
  faClose,
  faQuestion,
  faPlay,
  faPause,
  faRetweet,
  faClone,
  faUpload,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faEnvelope,
  faArchive,
  faTransporter,
  faClock,
  faCalendar,
  faBalenceScale,
} from '@fortawesome/free-solid-svg-icons';

const iconLibrary = {
  faHandsHelping,
  faEye,
  faSyncAlt,
  faUserPlus,
  faGrinBeam,
  faHandPointer,
  faTrophy,
  faCommentSlash,
  faCamera,
  faComments,
  faClose,
  faTheaterMasks,
  faInfoCircle,
  faArrowAltCircleLeft,
  faChevronDown,
  faChevronUp,
  faAward,
  faUserSecret,
  faEdit,
  faUser,
  faLink,
  faHourglassHalf,
  faThumbsUp,
  faCoins,
  faLaughBeam,
  faBuilding,
  faPhoneAlt,
  faVolumeMute,
  faCompressArrowsAlt,
  faExpand,
  faVideo,
  faVideoSlash,
  faMicrophone,
  faUndo,
  faTrash,
  faMicrophoneSlash,
  faBars,
  faPhone,
  faCheck,
  faTimes,
  faSignOutAlt,
  faPlus,
  faWifi,
  faBolt,
  faSmileWink,
  faMap,
  faSearchPlus,
  faComment,
  faFlag,
  faQuestion,
  faPlay,
  faPause,
  faRetweet,
  faClone,
  faUpload,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faEnvelope,
  faArchive,
  faTransporter,
  faClock,
  faCalendar,
  faBalenceScale,
};

export default function Icon({name = false, style = {}, color}) {
  if (!iconLibrary[name]) {
    console.log('Icon not found', name);
  }
  return (
    <FontAwesomeIcon
      icon={iconLibrary[name]}
      style={{
        fontSize: 30,
        color: color || global.color.gray3,
        marginBottom: 5,
        // @ts-ignore
        cursor: 'pointer',
        ...style,
      }}
    />
  );
}
