import {fetchWithParams} from './generalHelpers';
import {getLocalData} from './localStorage';

export const getJobDetails = async (params) => {
  const url = `/api/applicant/getJobDetails`;

  console.log('getJobDetails/', {url, params});
  try {
    const response = await fetchWithParams(url, params);
    // return Alert.alert('', JSON.stringify(response));
    return response;
  } catch (error) {
    return {error};
  }
};

export const apiSubmitStage = async (params) => {
  const url = `/api/applicant/apiSubmitStage`;

  console.log('apiSubmitStage/', {url, params});
  try {
    const response = await fetchWithParams(url, params);
    // return Alert.alert('', JSON.stringify(response));
    return response;
  } catch (error) {
    return {error};
  }
};

export const apiApplicantUpdateSubStage = async (params) => {
  const url = `/api/applicant/applicantUpdateSubStage`;

  console.log('apiApplicantUpdateSubStage/', {url, params});
  try {
    const response = await fetchWithParams(url, params);
    // return Alert.alert('', JSON.stringify(response));
    return response;
  } catch (error) {
    return {error};
  }
};

export const apiAdminGetJobInfo = async (params) => {
  return adminApiCall('apiAdminGetJobInfo', params);
};

export const apiAdminGetJobs = async (params) => {
  return adminApiCall('apiAdminGetJobs', params);
};

export const apiAdminSaveStage = async (params) => {
  return adminApiCall('apiAdminSaveStage', params);
};

export const apiAdminSaveJob = async (params) => {
  return adminApiCall('apiAdminSaveJob', params);
};

export const apiAdminGetCandidateInfo = async (params) => {
  return adminApiCall('apiAdminGetCandidateInfo', params);
};

export const apiAdminMoveToNextStage = async (params) => {
  return adminApiCall('apiAdminMoveToNextStage', params);
};

export const apiAdminUpdateAnyData = async (params) => {
  return adminApiCall('apiAdminUpdateAnyData', params);
};

export const apiMoveToAnotherJob = async (params) => {
  // return console.log('apiMoveToAnotherJob', {params});
  return adminApiCall('apiMoveToAnotherJob', params);
};

export const apiAdminGetCompanyInfo = async (params) => {
  return adminApiCall('apiAdminGetCompanyInfo', params);
};

export const apiAdminArchiveCanidate = async (params) => {
  return adminApiCall('apiAdminArchiveCanidate', params);
};
export const apiAdminUpdateSalary = async (params) => {
  return adminApiCall('apiAdminUpdateSalary', params);
};
export const apiGetAllApplicantSubmissions = async (params) => {
  return adminApiCall('apiGetAllApplicantSubmissions', params);
};

export const adminApiCall = async (path, params = {}) => {
  // adding validation data
  if (!params?.myCompanyUUID) {
    params.myCompanyUUID = getLocalData('myCompanyUUID');
  }
  if (!params?.myUserId) {
    params.myUserId = getLocalData('userId');
  }

  const url = `/api/admin/${path}`;

  console.log('adminApiCall/', {url, params});
  try {
    const response = await fetchWithParams(url, params);
    // return Alert.alert('', JSON.stringify(response));
    return response;
  } catch (error) {
    return {error};
  }
};
