import React from 'react';
import {
  Text,
  TouchableOpacity,
  View,
  ImageBackground,
  TextInput,
} from 'react-native';
import {uploadFile} from '../Helpers/Firebase/firebase';
import {v4 as uuid} from 'uuid';

const Card = (props) => {
  const {children, style = {}, topLine = false, backgroundImage} = props;
  return (
    <ImageBackground
      href={props.href}
      target="_blank"
      title={props.title}
      source={backgroundImage || null}
      style={{
        backgroundColor: 'white',
        position: 'relative',
        width: '100%',
        marginTop: 12,
        border: '1px solid #dadce0',
        borderRadius: 8,
        marginBottom: 12,
        padding: 24,
        paddingTop: 22,
        // overflow: 'hidden',
        minHeight: backgroundImage ? 120 : null,

        ...style,
      }}>
      {!!topLine && (
        <View
          style={{
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            height: 10,
            left: -1,
            position: 'absolute',
            top: -1,
            width: 'calc(100% + 2px)',
            backgroundColor: '#e87722',
          }}></View>
      )}
      {children}
    </ImageBackground>
  );
};

export default Card;
