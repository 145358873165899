import React, {createContext, useContext, useState, useMemo} from 'react';
import {useEffect} from 'react';

export const StateContext = createContext(null);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function JobsProvider({children, serverData}) {
  console.log('Provider Update: JobsProvider');
  // adding the event state info

  const [jobs, setJobs] = useState(serverData?.jobs || false);
  const [jobTemplates, setJobTemplates] = useState(
    serverData?.jobTemplates || false,
  );

  const [job, setJob] = useState(serverData?.job || false);
  const [stageQuestions, setStageQuestions] = useState(
    serverData?.stageQuestions || false,
  );

  const [stageDetails, setStageDetails] = useState(
    serverData?.stageDetails || false,
  );
  const [stages, setStages] = useState(serverData?.stages || false);

  const [applicants, setApplicants] = useState(serverData?.applicants || false);

  const updateApplicant = (newApplicant) => {
    console.log('updating applicant', {newApplicant});
    applicants?.map?.((oldApplicant, index) => {
      if (oldApplicant.uuid == newApplicant.uuid) {
        const newApplicants = [...applicants];
        newApplicants[index] = {...newApplicant};
        setApplicants(newApplicants);
      }
    });
  };

  console.log({applicants});

  useEffect(() => {
    console.log('updating page if server data changes');
    setJob(serverData?.job);
    setStages(serverData?.stages);
    setApplicants(serverData?.applicants);
    setStageDetails(serverData?.stageDetails);
    setStageQuestions(serverData?.stageQuestions);
  }, [serverData?.requestTime]);

  const contextValue = {
    jobs,
    setJobs,
    jobTemplates,
    setJobTemplates,
    job,
    setJob,
    stageQuestions,
    setStageQuestions,
    stageDetails,
    setStageDetails,
    stages,
    setStages,
    applicants,
    setApplicants,
    updateApplicant,
  };

  return (
    <StateContext.Provider value={{...contextValue}}>
      {children}
    </StateContext.Provider>
  );
}

export function useJobsProvider() {
  const context = useContext(StateContext);
  useEffect(() => {
    if (!window.state) {
      window.state = {};
    }
    window.state.JobsProvider = context;
  }, [context]);
  if (!context) {
    return {
      isDesktop: null,
      h1Style: null,
      h2Style: null,
      h3Style: null,
    };
  }
  return context;
}
