import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useEffect, useState} from 'react';
import {View} from 'react-native';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export default function StripeProvider({children}) {
  const [stripePromise, setStripePromise] = useState();
  useEffect(async () => {
    const newStripePromise = await loadStripe(
      'pk_test_lhSX5pAAgFzOaPmzHp9lbT3M',
    );
    setStripePromise(newStripePromise);
    console.log({newStripePromise});
  }, []);

  if (!stripePromise) {
    return <View></View>;
  }
  // return <View></View>;
  return <Elements stripe={stripePromise}>{children}</Elements>;
}
