global.color = {
  orange: 'rgba(232, 119, 34, 1)',
  teal: 'rgba(106, 174, 170, 1)',
  gray1: 'rgba(217, 217, 214, 1)',
  gray2: 'rgba(167, 168, 170, 1)',
  gray3: 'rgba(80, 87, 89, 1)',
  blue: 'rgba(83, 166, 196, 1)',
  blue_30: 'rgba(83, 166, 196, .3)',
  blue_50: 'rgba(83, 166, 196, .5)',
  blue_60: 'rgba(83, 166, 196, .6)',
  blue_80: 'rgba(83, 166, 196, .8)',
  yellow: 'rgba(255, 198, 0, 1)',
  white: 'rgba(255, 255, 255, 1)',
  white1: '#f2f2f2',
  white1_50: '#f2f2f288',
  orange_30: 'rgba(232, 119, 34, 0.3)',
  orange_50: 'rgba(232, 119, 34, 0.5)',
  teal_30: 'rgba(106, 174, 170, 0.3)',
  teal_50: 'rgba(106, 174, 170, 0.5)',
  teal_70: 'rgba(106, 174, 170, 0.7)',
  teal_80: 'rgba(106, 174, 170, 0.8)',
  gray1_30: 'rgba(217, 217, 214, 0.3)',
  gray1_50: 'rgba(217, 217, 214, 0.5)',
  gray1_80: 'rgba(217, 217, 214, 0.8)',
  gray2_50: 'rgba(167, 168, 170, 0.5)',
  gray3_30: 'rgba(80, 87, 89, 0.3)',
  gray3_50: 'rgba(80, 87, 89, 0.5)',
  gray3_60: 'rgba(80, 87, 89, 0.6)',
  gray3_80: 'rgba(80, 87, 89, 0.8)',
  yellow_50: 'rgba(255, 198, 0, 0.5)',
  white_30: 'rgba(255, 255, 255, 0.3)',
  white_50: 'rgba(255, 255, 255, 0.5)',
  white_70: 'rgba(255, 255, 255, 0.7)',
  white_80: 'rgba(255, 255, 255, 0.8)',
  white_90: 'rgba(255, 255, 255, 0.9)',
  black_30: 'rgba(0, 0, 0, 0.3)',
  black_50: 'rgba(0, 0, 0, 0.5)',
  black_60: 'rgba(0, 0, 0, 0.6)',
  black_80: 'rgba(0, 0, 0, 0.8)',
  gold: 'gold',
  silver: 'silver',
  bronze: '#d58c03',
  red: '#e22222',
  pink: '#E24260',
  magenta: '#de2795',
  purple: '#9166fb',
  lightblue: '#E5F2F6',
  brightgreen: '#2AD55C',
};

global.font = {
  gothic: 'Alternate Gothic No3 D',
  gothic1: 'Alternate Gothic No1 D', // not in use
  circular: 'Circular',
};

if (typeof window != 'undefined') {
  window.global = global;
}

String.prototype.toTitleCase = function () {
  return (
    this?.split(' ')
      ?.map?.((w) => w[0]?.toUpperCase() + w?.substring(1).toLowerCase())
      ?.join(' ') || ''
  );
};

Array.prototype.insert = function (index, items) {
  this.splice.apply(this, [index, 0].concat(items));
};

export default global;
