import React, {useState, useEffect} from 'react';

import {View, Text, ScrollView} from 'react-native';

// @ts-ignore
import Modal from '../Common/Modal';

import Link from 'next/link';

import {useModalState} from '../Providers/ModalProvider';
import {useUserState} from 'src/Providers/UserProvider';
import {useSafeNavigationProvider} from 'src/Providers/SafeNavigationProvider';
import {SearchIcon} from '@heroicons/react/outline';
import {adminApiCall} from 'src/Helpers/apiCalls';
import {TouchableOpacity} from 'react-native';
import {useMediaQuery} from 'src/Providers/MediaProvider';
import BoldMatchingText from 'src/Elements/Website/BoldMatchingText';

function getWorkedCompanies(applicant) {
  let work_experience = applicant.work_experience || '';

  // Parse if work_experience is a JSON string
  if (typeof work_experience === 'string') {
    work_experience = JSON.parse(work_experience);
  }

  // Get the companies
  const companies = [];
  work_experience.map((work) => {
    if (work.company) {
      companies.push(`${work.company?.trim?.()} (${work.role?.trim?.()})`);
    }
  });

  // Handle the format of the output
  const companiesString = companies.reduce((acc, curr, i, arr) => {
    if (i === 0) {
      return `${curr}`;
    } else if (i === arr.length - 1) {
      return `${acc}, and ${curr}`;
    } else {
      return `${acc}, ${curr}`;
    }
  }, '');

  return companiesString;
}

export default function SearchModal() {
  const {searchOpen, setSearchOpen} = useModalState();
  const [suggestions, setSugestions] = useState([{}, {}]);
  const [searchText, setSearchText] = useState(null);
  const {company, firebaseIdToken} = useUserState();
  const {h1Style, h2Style} = useMediaQuery();
  const {safeNavigate, unSafeNavigate} = useSafeNavigationProvider();
  useEffect(() => {
    if (searchText) {
      adminApiCall('apiGetSuggestions', {
        company_uuid: company?.uuid,
        searchText,
        firebaseIdToken,
      }).then((response) => {
        console.log({response});
        if (response?.suggestedApplicants || response?.suggestedJobs) {
          setSugestions(response);
        } else {
          setSugestions(null);
        }
      });
    } else {
      setSugestions(null);
    }
  }, [searchText]);
  return (
    <Modal
      open={searchOpen || false}
      zIndex={10000}
      handleClose={() => setSearchOpen(0)}
      style={prompt.style || {}}
      backgroundStyle={prompt.backgroundStyle}>
      <View
        style={{
          height: '100vh',
          maxHeight: 1000,
          width: 500,
          padding: 40,
          flex: 1,
          position: 'relative',
        }}>
        <ScrollView style={{flex: 1}}>
          <Text style={{...h1Style, textAlign: 'center'}}>
            Candidate And Job Search
          </Text>
          <div>
            <div className="mt-1 relative rounded-md">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500 sm:text-sm">
                  {' '}
                  <SearchIcon
                    className={
                      'text-gray-400 group-hover:text-gray-500 mr-4 h-6 w-6'
                    }
                    aria-hidden="true"
                  />
                </span>
              </div>
              <input
                name="price"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                id="price"
                className="grayplaceholder focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                placeholder="Search Candidates"
                style={{
                  background: 'transparent',
                  paddingLeft: 30,
                  fontSize: 11,
                }}
              />
            </div>
          </div>
          {!!suggestions && searchText && (
            <div style={{flex: 1}}>
              {!!suggestions?.suggestedJobs?.[0] && (
                <Text style={{...h2Style}}>Jobs</Text>
              )}
              {suggestions?.suggestedJobs?.map?.((suggestion) => {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      console.log('clicked');
                      unSafeNavigate(`/client/job/${suggestion.uuid}`);
                    }}>
                    {suggestion.title}
                  </TouchableOpacity>
                );
              })}
              {!!suggestions?.suggestedApplicants?.[0] && (
                <Text style={{...h2Style}}>Applicants</Text>
              )}

              {suggestions?.suggestedApplicants?.map?.((applicant) => {
                return (
                  <Link
                    style={{padding: 10}}
                    href={`/client/job/${applicant.job_uuid}/${applicant.stage_uuid}/${applicant.applicant_uuid}`}>
                    <View
                      style={{
                        backgroundColor: applicant.applicant_archived
                          ? '#ff000055'
                          : '#ffff0055',
                        borderRadius: 5,
                        padding: 10,
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <View
                        style={{
                          height: 50,
                          width: 50,
                          marginTop: 5,
                          marginBottom: 5,
                          borderRadius: 25,

                          backgroundColor: 'green',
                          top: 0,
                          justifyContent: 'center',
                          alignItems: 'center',

                          position: 'relative',
                        }}>
                        <img
                          src={applicant?.photo}
                          style={{
                            width: 45,
                            height: 45,
                            minWidth: 45,
                            minHeight: 45,
                            borderRadius: 22.5,
                            backgroundColor: 'green',
                          }}
                          loading="lazy"
                        />
                      </View>
                      <View style={{paddingLeft: 10, paddingLeft: 10}}>
                        <Text>
                          <BoldMatchingText
                            text={`${applicant.first_name} ${applicant.last_name} - `}
                            searchText={searchText}
                          />
                          <Text style={{fontSize: 14, marginTop: 10}}>
                            {applicant.title?.replace(`Let's Roam `, '')}
                          </Text>
                          <Text style={{fontSize: 14}}>
                            {` (${applicant.applicant_stage})`}
                          </Text>
                        </Text>
                        {!!applicant.work_experience && (
                          <BoldMatchingText
                            style={{fontSize: 10}}
                            text={`Worked at: ${getWorkedCompanies(applicant)}`}
                            searchText={searchText}
                          />
                        )}
                      </View>
                    </View>
                  </Link>
                );
              })}
            </div>
          )}
        </ScrollView>
      </View>
    </Modal>
  );
}
