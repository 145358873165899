import React from 'react';
export default function Dropdown(props) {
  const {label, data, id, value, style = {}, handleChange} = props;
  return (
    <select
      // @ts-ignore
      id={id}
      style={{
        width: 380,
        height: 40,
        border: `1px solid ${global.color.gray1}`,
        ...style,
      }}
      value={value}
      onChange={(e) => {
        console.log('select change', {handleChange, value: e.target.value});
        handleChange && handleChange(e.target.value);
      }}>
      {data?.map?.((item, index) => {
        // console.log({ audioInput });
        return (
          <option
            key={`${item.deviceId || item.key || item.label}-${index}`}
            disabled={!!item.disabled}
            value={item.deviceId || item.value || ''}>
            {item.label}
          </option>
        );
      })}
    </select>
  );
}
