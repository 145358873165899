import React from 'react';
import {v4} from 'uuid';

const createTemplate = (
  type = 'question',
  job_uuid = null,
  stage_uuid = null,
) => {
  if (type === 'question') {
    return {
      uuid: v4().substr(0, 12),
      job_uuid,
      stage_uuid,
      type: 'fill_in_the_blank',
      field: null,
      required: 1,
    };
  } else if (type === 'section') {
    return {
      uuid: v4().substr(0, 12),
      job_uuid,
      title: '',
      description_for_user: null,
    };
  }
};

const gradeScore = (grade) => {
  if (!grade) {
    return 0;
  } else if (grade == 'A+') {
    return 1;
  } else if (grade == 'A') {
    return 2;
  } else if (grade == 'A-') {
    return 3;
  } else if (grade == 'B+') {
    return 4;
  } else if (grade == 'B') {
    return 5;
  } else if (grade == 'B-') {
    return 6;
  } else if (grade == 'C+') {
    return 7;
  } else if (grade == 'C') {
    return 8;
  } else if (grade == 'C-') {
    return 9;
  } else if (grade == 'D') {
    return 10;
  } else if (grade == 'F') {
    return 11;
  }
};

const getApplicantStatusLabel = (applicant, selectedStage) => {
  const statusOptions = getStageStatusOptions(selectedStage?.stage_type);
  const currentStatus = statusOptions?.find(
    (status) => status.value == applicant?.stage_status,
  );
  // console.log({statusOptions, currentStatus});
  return currentStatus?.label || '';
};

const getStageStatusOptions = (stage_type, addStatusToLabel = false) => {
  var statusOptions = [];
  if (stage_type === 'video_interview' || stage_type === 'phone_interview') {
    statusOptions = [
      {value: 'not_started', label: 'Needs Interview Invite'},
      {
        value: 'waiting_on_canidate',
        label: addStatusToLabel
          ? 'Invite Sent'
          : 'Invite Sent (Response Needed)',
      },
      {
        value: 'inteview_scheduled',
        label: 'Interview Scheduled',
      },
      {value: 'needs_review', label: 'Interview Finished'},
    ];
  } else if (stage_type === 'canidate_form') {
    statusOptions = [
      {value: 'not_started', label: 'Waiting On Candidate'},
      {value: 'stage_failed', label: 'Did Not Pass'},
      {value: 'needs_review', label: 'Needs Review'},
    ];
  } else if (stage_type === 'on_demand_video') {
    statusOptions = [
      {value: 'not_started', label: 'Video Not Started'},

      {value: 'needs_review', label: 'Video Finished'},
    ];
  } else if (stage_type === 'admin') {
    statusOptions = [
      {value: 'not_started', label: 'Not Started'},
      {value: 'in_progress', label: 'In Progress'},
      {value: 'done', label: 'Done'},
      {value: 'waiting_on_canidate', label: 'Waiting on Candidate'},
    ];
  }
  if (addStatusToLabel) {
    statusOptions?.map?.((item, index) => [
      (statusOptions[index]['label'] =
        'Status: ' + statusOptions[index]['label']),
    ]);
  }
  return statusOptions;
};

const getDepartmentsOptions = () => {
  return [
    {value: 'sales', label: 'Sales Department'},
    {value: 'marketing', label: 'Marketing Department'},
    {value: 'customer_service', label: 'Customer Service Department'},
    {value: 'dev', label: 'Dev Department'},
    {value: 'am', label: 'AM Department'},
    {value: 'other', label: 'Other Department'},
  ];
};

const getAdminLevels = () => {
  return [
    {value: 1, label: 'Visible To Whole Team'},
    {value: 2, label: 'Visible to Department Heads + Recruiters + C-Suite'},
    {value: 3, label: 'Visible to Recruiters + C-Suite'},
    {value: 4, label: 'Visible Only To C-Suite'},
  ];
};

const getDepartmentLabel = (department = 'sales') => {
  var departments = getDepartmentsOptions();
  var selectedDepartment = departments?.find((a) => a.value == department);
  return selectedDepartment?.label;
};

const formatJobsForDropdown = (jobs = [], useUUIDAsKey = false) => {
  var formatedJobs = jobs
    ?.sort((a, b) => {
      if (a.archived == b.archived) {
        return a.published > b.published ? -1 : 1;
      }
      return a.archived < b.archived ? -1 : 1;
    })
    ?.map?.((job, newJobIndex) => {
      var label;
      if (job.archived) {
        label = '(ARCHIVED)';
      } else if (job.published) {
        label = '(Published)';
      } else {
        label = '(Un-Published)';
      }
      return {
        label: label + ' ' + job.title,
        value: useUUIDAsKey ? job.uuid : newJobIndex,
      };
    });

  return formatedJobs;
};

const getJobFields = (isNew = false, jobTemplates = null, jobs = []) => {
  var formatedJobs = formatJobsForDropdown(jobs, true);

  formatedJobs = [
    {
      label: 'No Redirect',
      value: null,
    },
    ...formatedJobs,
  ];

  const jobFields = [
    {
      field: 'title',
      label: 'Title',
      additionalInfo: 'This is the customer facing title for the job.',
    },
    {
      field: 'description',
      label: 'Description (For User, optional)',
      type: 'long_form_input',
      additionalInfo: 'This is the customer facing description for the job.',
    },
    {
      field: 'job_description_link',
      label: 'Public Job Description Link',
      additionalInfo:
        'This is the link to the public job description for the job.',
    },
    {
      field: 'score_card',
      label: 'Scorecard (Internal)',
      type: 'long_form_input',
      additionalInfo: 'This is the customer facing description for the job.',
    },
    {
      field: 'score_card_link',
      label: 'Score Card Link (Internal)',

      additionalInfo: 'This is the customer facing description for the job.',
    },
    {
      field: 'trello_card_link',
      label: 'Trello Card Link (Internal)',

      additionalInfo: 'This is a link to the trello card for this job',
    },
    {
      field: 'hiring_schedule_link',
      label: 'Hiring Schedule Link (Internal)',

      additionalInfo: 'This is a link to the hiring schedule for this job',
    },
    {
      field: 'live_job_post_link',
      label: 'Live Job Post Link',

      additionalInfo: 'This is a link to the trello card for this job',
    },
    {
      field: 'department',
      label: 'Department',
      type: 'dropdown',
      additionalInfo: 'What department is this role in?',
      dropdownOptions: getDepartmentsOptions(),
    },
    {
      field: 'admin_level_visibility_minimum',
      label: 'Internal Visitibity',
      type: 'dropdown',
      additionalInfo: 'Who can see this job post?',
      dropdownOptions: getAdminLevels(),
    },

    {
      field: 'published',
      label: 'Published',
      type: 'dropdown',
      additionalInfo: 'This determines if applicants can apply for the job.',
      dropdownOptions: [
        {value: 0, label: 'Not Published'},
        {value: 1, label: 'Published'},
      ],
    },
    {
      field: 'redirect_uuid',
      label: 'Redirect To Another Job',
      type: 'dropdown',
      dropdownOptions: formatedJobs || [],
      getShouldDisplay: (job) => {
        return !job.published;
      },
    },
    {
      field: 'redirect_uuid',
      label: 'Redirect To Another Job',
      type: 'dropdown',
      dropdownOptions: formatedJobs || [],
      getShouldDisplay: (job) => {
        return !job.published;
      },
    },
    {
      field: 'archived',
      label: 'Archived',
      type: 'dropdown',
      dropdownOptions: [
        {value: 0, label: 'Not Archived'},
        {value: 1, label: 'Archived'},
      ],
      getShouldDisplay: (job) => {
        return !job.published;
      },
    },
  ];
  const additionalInfo =
    'This field determines what template is auto-loaded in with its questions.';
  if (isNew) {
    jobFields.pop();
    jobFields.pop();
    const stageTemplatesDropdownOptions = {};
    jobTemplates.map?.((jobTemplate, index) => {
      const {template_order, title, uuid} = jobTemplate;
      if (!stageTemplatesDropdownOptions[template_order]) {
        stageTemplatesDropdownOptions[template_order] =
          index === 0
            ? []
            : [
                {value: 'template-standard-blank', label: 'Blank Template'},
                ,
                {value: 'skip', label: 'Skip Section'},
              ];
      }
      stageTemplatesDropdownOptions[template_order].unshift({
        value: uuid,
        label: 'Template: ' + title,
      });
    });

    jobFields.push(
      {
        field: 'stage_0',
        label: 'Stage 1: Initial Application',
        type: 'dropdown',
        additionalInfo,
        dropdownOptions: stageTemplatesDropdownOptions[0],
      },
      {
        field: 'stage_1',
        label: 'Stage 2: Appication Field',
        type: 'dropdown',
        additionalInfo,
        dropdownOptions: stageTemplatesDropdownOptions[1],
      },
      {
        field: 'stage_2',
        label: 'Stage 3: On Demand Video',
        type: 'dropdown',
        additionalInfo,
        dropdownOptions: stageTemplatesDropdownOptions[2],
      },
      {
        field: 'stage_3',
        label: 'Stage 4: Top Grading Interview',
        type: 'dropdown',
        additionalInfo,
        dropdownOptions: stageTemplatesDropdownOptions[3],
      },
      {
        field: 'stage_4',
        label: 'Stage 5: Final Interview',
        type: 'dropdown',
        additionalInfo,
        dropdownOptions: stageTemplatesDropdownOptions[4],
      },
      {
        field: 'stage_5',
        label: 'Stage 6: Reference Check',
        type: 'dropdown',
        additionalInfo,
        dropdownOptions: stageTemplatesDropdownOptions[5],
      },
      {
        field: 'stage_6',
        label: 'Stage 7: Offer Accepted',
        type: 'dropdown',
        additionalInfo,
        dropdownOptions: stageTemplatesDropdownOptions[6],
      },
    );
  }
  return jobFields;
};

const getStageEditFields = (isTemplate, showQuestionCount = false) => {
  const nextStageTypes = [
    {value: '0', label: 'Manual Review Of Applicant Responses'},
    {value: '1', label: 'Automatically Move To Next Quiz'},
  ];

  const nextStagePoints = [
    {value: '0', label: 'Unscored'},
    {value: '10', label: '10 Points'},
    {value: '20', label: '20 Points'},
    {value: '30', label: '30 Points'},
    {value: '40', label: '40 Points'},
    {value: '35', label: '35 Points'},
    {value: '40', label: '40 Points'},
    {value: '50', label: '50 Points'},
  ];

  const fields = [
    {
      field: 'title',
      label: 'Title',
      additionalInfo:
        'This is a user facing short title of what the section is, that appears at the top of the application step. Make sure not to include confidential information.',
    },
    {
      field: 'description_for_user',
      label: 'Description (For User, optional)',
      maxLength: 900,
      additionalInfo:
        'This is a user facing description of what the section is. Make sure not to include confidential information.',
      type: 'long_form_input',
    },
    {
      field: 'stage_type',
      label: 'Interview Type',
      type: 'dropdown',
      additionalInfo:
        'This field logs the interview type. Forms include the initial application done by the canidate. Pre-recorded bideo challenges are challenges that the applicants respond to in their own time with video. Video/phone interviews have questions answered by the applicant but logged by the interviewer. Finally, admin steps involve backend steps such as reference checks.',
      dropdownOptions: [
        {label: 'Form or Quiz Filled Out By Canidate', value: 'canidate_form'},
        {
          label: 'Pre-Recorded Video Challenges Done By Interviewee on Demand',
          value: 'on_demand_video',
          changeTemplate: {
            email_title: `Let's Roam Job: Next Steps: Pre-recorded Interview`,
            email_body: `Hi [[candidate_name]],
            \nThank you for applying to [[comany]]. You have passed the first steps of the application with your resume. 
            \nThe next step of the application process is an on-demand video screening. This involves answering pre-recorded video responses to standard questions. This typically takes about 30 minutes. These recorded video interviews allow for us to get to know you a little bit better. 
            \nPlease complete the video interview at your convenience. 
            \nAll the best / Kind regards,
            \n[[user_full_name]]`,
          },
        },
        {
          label: 'Video Interview With Canidate',
          value: 'video_interview',
        },
        {label: 'Phone Interview With Canidate', value: 'phone_interview'},
        {
          label: 'Admin Steps, Such as References, Offer Sending, & Acceptance',
          value: 'admin',
        },
      ],
    },
    ...(isTemplate
      ? [
          {
            field: 'template_order',
            label: 'Template Section',
            type: 'dropdown',
            additionalInfo:
              'This field determines what round the template shows up for in the create hunt template section. For instance, are these custom application questions or a custom on demand video.',
            dropdownOptions: [
              {label: 'Step 1: Initial Application', value: '0'},
              {label: 'Step 2: Skills Quiz', value: '1'},
              {
                label: 'Step 3: On Demand Video',
                value: '2',
                changeTemplate: {
                  stage_type: 'on_demand_video',
                  email_title: `Let's Roam Job: Next Steps: Pre-recorded Interview`,
                  email_body: `Hi [[canidate_first_name]],
    
                Thank you for applying to [[comany]]. You have passed the first steps of the application with your resume. 
                
                The next step of the application process is an on-demand video screening. This involves answering pre-recorded video responses to standard questions. This typically takes about 30 minutes. These recorded video interviews allow for us to get to know you a little bit better. 
                
                Please complete the video interview at your convenience. 
                
                All the best / Kind regards,
                
                [[user_full_name]]`,
                },
              },
              {
                label: 'Step 4: Top Grading Interview',
                value: '3',
                changeTemplate: {
                  stage_type: 'video_interview',
                  email_title: `Second Round Interview Request`,
                  email_body: `Hi [[canidate_first_name]],
                  \nI really enjoyed chatting with you and learning more about you. We would like to do a second-round video interview in the next 2 days. 
                  \nThis interview will be led by myself and I will expand on what the role entails. The interview will be a combination of a cultural interview and going through your resume. You will also be able to learn more about our company, our rapid growth this year, our team, and more. 
                  \nHere is the link to book a meeting on our shared Calendar: [[60_minute_meeting_link]]
                  \nBest,
                  \n[[user_first_name]]`,
                },
              },
              {
                label: 'Step 5: Final Interview',
                value: '4',
                changeTemplate: {
                  stage_type: 'video_interview',
                  email_title: `Final Round Interview`,
                  email_body: `Hi [[canidate_first_name]],
                  \nI really enjoyed chatting with you and learning more about you. We would like to do a final round interview with you in the next 2 business days.
                  \nThis interview will be led by myself and I will expand on what the role entails. The interview will be a combination of a cultural interview and going through your resume. You will also be able to learn more about our company, our rapid growth this year, our team, and more. 
                  \nHere is the link to book a meeting on our shared Calendar: [[60_minute_meeting_link]]
                  \nBest,
                  \n[[user_first_name]]`,
                },
              },
              {label: 'Step 6: References', value: '5'},
              {label: 'Step 7: Offer Sent', value: '6'},
              {label: 'Step 8: Offer Accepted', value: '7'},
            ],
          },
        ]
      : []),
    {
      field: 'points_to_auto_move_on',
      label: 'Points To Auto Move On',
      type: 'dropdown',
      getShouldDisplay: (stageDetails) => {
        return (
          stageDetails?.stage_type === 'canidate_form' ||
          stageDetails?.stage_type === 'on_demand_video'
        );
      },
      dropdownOptions: nextStagePoints,
      additionalInfo: 'Minimum point value',
    },
    {
      field: 'auto_next_round',
      label: 'Auto Move On',
      type: 'dropdown',
      getShouldDisplay: (stageDetails) => {
        return stageDetails?.stage_type === 'canidate_form';
      },
      dropdownOptions: nextStageTypes,
      additionalInfo:
        'This field determines if the applicant automatically moves on to the next stage, when the previous stage questions are completed. For example, if there is a skills quiz after the intial application, automoving on would make sense.',
    },
    {
      field: 'email_title',
      maxLength: 400,
      label: 'Email Invite Template Title',
      // getShouldDisplay: (stageDetails) => {
      //   return (
      //     stageDetails?.stage_type === 'video_interview' ||
      //     stageDetails?.stage_type === 'phone_interview' ||
      //     stageDetails?.stage_type === 'on_demand_video'
      //   );
      // },
      additionalInfo:
        'This  field is used when sending email interview requests. Allowed variables are {{interview_link}}, {{canidate_full_name}}, {{job_title}}',
    },
    {
      field: 'email_body',
      label: 'Email Invite Template Body',
      maxLength: 4000,
      type: 'long_form_input',
      // getShouldDisplay: (stageDetails) => {
      //   return (
      //     stageDetails?.stage_type === 'video_interview' ||
      //     stageDetails?.stage_type === 'phone_interview' ||
      //     stageDetails?.stage_type === 'on_demand_video'
      //   );
      // },
      additionalInfo:
        'This  field is used when sending email interview requests. Allowed variables are {{interview_link}}, {{canidate_full_name}}, {{job_title}}',
    },

    ...(showQuestionCount
      ? [
          {
            field: 'stage_question_count',
            label: 'Number Of Questions',
            type: 'data_link',
            additionalInfo: 'The number of questions in the section.',
            unit: 'Questions',
          },
        ]
      : []),
  ];
  return fields;
};

const formatClock = (time) => {
  var sec_num = parseInt(time, 10); // don't forget the second param
  var minutes = Math.floor(sec_num / 60);
  var seconds = sec_num - minutes * 60;

  // if (minutes < 10) {
  //   minutes = '0' + minutes;
  // }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return minutes + ':' + seconds;
};
export {
  createTemplate,
  formatClock,
  getJobFields,
  getDepartmentLabel,
  getStageEditFields,
  getApplicantStatusLabel,
  getStageStatusOptions,
  gradeScore,
  formatJobsForDropdown,
};
