import React from 'react';
import {
  Text,
  TouchableOpacity,
  View,
  ImageBackground,
  TextInput,
} from 'react-native';
import {uploadFile} from '../Helpers/Firebase/firebase';
import {v4 as uuid} from 'uuid';
import {useState} from 'react';
import {useModalState} from '../Providers/ModalProvider';

const ToolTip = (props) => {
  const {children, tip, style} = props;
  const {prompt, setPrompt} = useModalState();
  if (!tip) {
    return <View style={style}>{children}</View>;
  }
  return (
    <TouchableOpacity
      nativeID={tip}
      style={{position: 'relative'}}
      style={style}
      onPress={() => {
        console.log({tip});
        // setShowToolTip(true);
        setPrompt({
          open: true,
          titleText: 'Additional Info',
          text: tip,
          buttonText: 'Thanks',
          backgroundStyle: {backgroundColor: 'rgba(0, 0,0, 0.7)'},
          hideInput: true,
          handlePress: (text) => {
            setPrompt({open: false});
          },
        });
      }}>
      {children}
      {/* {showToolTip && (
        <View style={{position: 'absolute', bottom: 0, background: 'white'}}>
          {tip}
        </View>
      )} */}
    </TouchableOpacity>
  );
};

export default ToolTip;
