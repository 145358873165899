// import 'react-phone-number-input/style.css';
import React from 'react';
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input';

//
function LoginModalPhoneInput(props) {
  const {phone, setPhone} = props;
  return (
    // @ts-ignore
    <PhoneInput
      placeholder="Enter phone number"
      style={{
        height: 60,
        textAlign: 'center',
        minWidth: 270,
        fontSize: 14,
      }}
      value={phone}
      defaultCountry={'US'}
      onChange={(phoneNumber) => {
        console.log({phoneNumber});
        setPhone(phoneNumber);
      }}
    />
  );
}

export default LoginModalPhoneInput;
