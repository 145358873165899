import {onValue, ref, set} from '@firebase/database';
import {useEffect, useState} from 'react';

import {
  db,
  updateFirebaseData,
  // @ts-ignore
} from '../Helpers/Firebase/firebase';

// designed to sync with sql
export default function useShiftState() {
  const [shiftHeld, setShiftHeld] = useState(0);

  function downHandler({key}) {
    if (key === 'Shift') {
      setShiftHeld(true);
    }
  }

  function upHandler({key}) {
    if (key === 'Shift') {
      setShiftHeld(false);
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  return [shiftHeld];
}

// jobData
// jobData/:table/job_uuid/field/data
